<div class="d-flex" *ngIf="control">
  <ng-select class="flex-fill" [items]="items" [bindLabel]="bindLabel" [bindValue]="bindValue" [multiple]="multiple"
    [groupBy]="groupBy" [groupValue]="groupValue" [selectableGroupAsModel]="selectableGroupAsModel"
    [selectableGroup]="selectableGroup" [addTag]="addTag ? addValue.bind(this) : null" [closeOnSelect]="!multiple"
    [(ngModel)]="_value" [placeholder]="placeholder" [notFoundText]="notFoundText" [ngbTooltip]="tooltip"
    [clearSearchOnAdd]="true" [ngClass]="{ 'is-invalid': control.invalid && control.touched }" [readonly]="_disabled"
    (change)="onChange($event)" (close)="onClose()" (paste)="onPaste($event)">

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div *ngFor="let item of items ? items.slice(0, numberOfBadges) : []; let i = index" class="ng-value">
        <div class="badge rounded-pill df-closable bg-light">{{ bindLabel ? item[bindLabel] : item }}
          <button *ngIf="!_disabled" type="button" attr.aria-label="Remove {{ bindLabel ? item[bindLabel] : item }}"
            [dfManageBadgeEventsSelect]="[clear, item]">
            <span aria-hidden="true" class="icon-times"></span>
          </button>
        </div>
      </div>

      <div *ngIf="items.length > numberOfBadges">
        <span i18n="@@upp.global.labels.plusOthers">+{{ items.length - numberOfBadges }} others...</span>
      </div>
    </ng-template>

    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
      <div *ngIf="selectableGroup; else nonSelectableGroup" class="form-check">
        <input id="item-group-{{index}}" class="form-check-input"
          name="item-group-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
          [indeterminate]="isIndeterminate(item$)" />
        <label class="form-check-label" for="item-group-{{index}}"
          (click)="$event.preventDefault();" [dfOptionHighlight]="search">
          {{ bindLabel ? item[bindLabel] : item }}
        </label>
      </div>
      <ng-template #nonSelectableGroup>
        {{ bindLabel ? item[bindLabel] : item }}
      </ng-template>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
      <div [ngClass]="{'ms-4': groupBy ? item[groupBy] : false}">
        <div *ngIf="multiple; else singleOption" class="form-check">
          <input id="item-{{index}}" name="item-{{index}}" type="checkbox"
            [ngModel]="item$.selected" class="form-check-input" />
          <label class="form-check-label" for="item-{{index}}" (click)="$event.preventDefault()"
            [dfOptionHighlight]="search">{{ bindLabel ? item[bindLabel] : item }}</label>
        </div>
        <ng-template #singleOption>{{ bindLabel ? item[bindLabel] : item }}</ng-template>
      </div>
    </ng-template>
  </ng-select>
  <div *ngIf="this.lookupOptions">
    <button *ngIf="this.lookupOptions" i18n="@@upp.global.button.lookup" type="button"
      class="btn btn-primary mb-2 mb-md-0 me-2 ms-2 actionButton" (click)="openLookup()">
      Lookup
    </button>
  </div>
</div>
