// Journey types
export const ANY_SEGMENT_CONNECTION = 'any';
export const journeyTypesOptions = [
{
  value: 'NonStop',
  label: 'Non Stop'
},
{
  value: 'Direct',
  label: 'Direct'
},
{
  value: 'COG',
  label: 'CoG'
},
{
  value: 'Connection2',
  label: 'Connection with 2 segments',
  parent: ANY_SEGMENT_CONNECTION
},
{
  value: 'Connection3',
  label: 'Connection with 3 segments',
  parent: ANY_SEGMENT_CONNECTION
},
{
  value: 'Connection4',
  label: 'Connection with 4 segments',
  parent: ANY_SEGMENT_CONNECTION
},
{
  value: 'Connection5',
  label: 'Connection with 5 segments',
  parent: ANY_SEGMENT_CONNECTION
}
];
export const journeyTypesGroupValueFn = (_groupName: string) => ({ value: ANY_SEGMENT_CONNECTION, label: 'Connection from 2 to 5 segments' });
