import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { SortEvent } from '../../../core/util/table-sort.directive';
import { SearchResultTableComponent } from '../../../base/search/search-result-table/search-result-table.component';
import { TYPE_DELETE } from '../../../model/translated-notification-messages';
import { NavigationService } from '../../../service/core/navigation.service';
import { UppComponentNames } from '../../../service/model';
import { LookupOptions } from '../../../core/util/lookup-options';
import { DeleteRequest } from '../../../service/model/common/request/delete-request';
import { UppNotification } from '../../../model/notification';
import { FAILED_STATUS, WARNING_STATUS } from '../../../service/handler/response-handler';
import { UserDetailsService } from '../../../service/user-details.service';
import { ConfigurationService } from '../../../service/configuration/configuration.service';
import { FeatureFlags } from '../../../core/util/resources';
import { getByPath, getPropertyByKey } from '../../../util/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ama-ng-upp-basic-search-result-table',
  templateUrl: './basic-search-result-table.component.html',
  styleUrls: ['./basic-search-result-table.component.scss']
})
export class BasicSearchResultTableComponent extends SearchResultTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() searchResults!: SearchResult[];
  @Input() selectedNames!: Set<string | undefined>;
  @Input() isLookup!: boolean;
  @Input() lookupValues!: string[];
  @Input() lookupOptions!: LookupOptions;
  @Input() sourceComponent!: UppComponentNames;
  @Input() entryType!: EntryType;
  @Input() entryToUpdateCreator!: ((id: string, name: string, organization: string, version: string, ruleVersion: number) => DeleteRequest);
  // Path to id property within the entity
  @Input() idPath?: string;
  @Input() entryToUpdateNameResolver!: (entry: any) => string;
  @Input() recordToSearchResult!: (record: any) => SearchResult;
  @Input() copyForbidden?: boolean;
  @Input() managePermission!: string | string[] | ((record: any) => string | string[]);
  @Input() displayableFields?: DisplayableField[];

  @Output() deleteEntry = new EventEmitter<DeleteRequest>();
  @Output() selectEntries = new EventEmitter<Set<string | undefined>>();

  private readonly UNDEFINED_RECORD_A_ERROR = 'recordA is null or undefined';
  private readonly UNDEFINED_RECORD_B_ERROR = 'recordB is null or undefined';

  entryToUpdate!: DeleteRequest;
  currentPage = 1;
  pageSize = 10;
  resultsNumber = 0;
  resultsToDisplay: any[] = [];
  dynamicExclusionV2: boolean | null = null;

  subscription: Subscription | null = null;

  private selectAllChecked = false;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService
  ) {
    super();
    this.subscription = this.configurationService.getParameter$(FeatureFlags.dynamicExclusionV2).subscribe((value) => {
      this.dynamicExclusionV2 = value ?? false;
    });
  }

  ngOnInit(): void {
    if (this.lookupValues?.length > 0) {

      if (this.lookupOptions.singleSelect) {
        this.selectedNames.add(this.lookupValues[0]);
      } else {
        this.lookupValues.forEach((value) => this.selectedNames.add(value));
      }
    }
  }

  ngOnChanges(): void {
    if (this.searchResults) {
      this.resultsNumber = this.searchResults.length;
    }
  }

  changePage() {
    if (this.searchResults) {
      this.resultsNumber = this.searchResults.length;
      const current = (this.currentPage - 1) * this.pageSize;
      this.resultsToDisplay = this.searchResults.slice(current, current + Number(this.pageSize));
    }
  }

  showPrevPage() {
    this.currentPage--;
  }

  showNextPage() {
    this.currentPage++;
  }

  isSearchResultAvailable(): boolean {
    if (!this.searchResults || this.searchResults.length === 0) {
      return false;
    }

    this.changePage();

    return this.searchResults[0].statusType !== WARNING_STATUS && this.searchResults[0].statusType !== FAILED_STATUS;
  }

  filterSuccessRecords(): SearchResult[] {
    if (this.searchResults) {
      this.resultsNumber = this.searchResults.length;
      const current = (this.currentPage - 1) * this.pageSize;
      return this.searchResults.slice(current, current + Number(this.pageSize));
    }
    return [];
  }

  isSelected(name: string): boolean {
    return this.selectedNames?.has(name);
  }

  toggleSelection(name: string): void {
    if (this.selectedNames.has(name)) {
      this.selectedNames.delete(name);
    } else {
      if (this.lookupOptions.singleSelect) {
        this.selectedNames.clear();
      }
      this.selectedNames.add(name);
    }
  }

  executeActionAfterConfirmation() {
    this.getConfirmation = false;
    if (this.confirmationType === TYPE_DELETE) {
      return this.deleteResultListEntry();
    } else {
      const id = this.idPath
        ? getByPath(this.entryToUpdate, this.idPath)
        : getPropertyByKey(this.entryToUpdate, this.entryType).id;

      return this.copyDetails(id);
    }
  }

  deleteResultListEntry() {
    this.getConfirmation = false;
    this.deleteEntry.emit(this.entryToUpdate);
  }

  getRecordsForSorting(a: SearchResult, b: SearchResult, assertId = false) {
    const recordA = this.recordToSearchResult(a);
    const recordB = this.recordToSearchResult(b);
    if (!recordA) {
      throw new Error(this.UNDEFINED_RECORD_A_ERROR);
    }
    if (!recordB) {
      throw new Error(this.UNDEFINED_RECORD_B_ERROR);
    }
    if (assertId && !recordA.id) {
      throw new Error('recordA.id is null or undefined');
    }
    if (assertId && !recordB.id) {
      throw new Error('recordB.id is null or undefined');
    }
    return { a: { ...recordA, id: recordA.id as string }, b: { ...recordB, id: recordB.id as string} };
  }

  private resetNonSelectedSortingDirections(column: string) {
    // Reset the sorting of other headers than the clicked one
    this.sortingHeaders?.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
  }

  abortConfirmation() {
    this.entryToUpdate = this.entryToUpdateCreator('', '', '', '1.0', 0);
    this.confirmationType = undefined;
    this.getConfirmation = false;
  }

  sortByActiveStatus(arrayForSort: SearchResult[], direction: string) {
    arrayForSort.sort((a, b) => {
      const records = this.getRecordsForSorting(a, b);
      return (
        Number(getPropertyByKey(records.b, 'ruleStatusActive')) -
        Number(getPropertyByKey(records.a, 'ruleStatusActive'))
      );
    });

    if (direction === 'desc') {
      arrayForSort.reverse();
    }
    this.searchResults = arrayForSort;
  }

  sortByAlphabeticalOrder(arrayForSort: SearchResult[], column: string, direction: string) {
    arrayForSort.sort((a, b) => {
      const records = this.getRecordsForSorting(a, b);
      return this.stringAlphabeticalCompare(
        getPropertyByKey(records.a, column).toString(),
        getPropertyByKey(records.b, column).toString()
      );
    });

    if (direction === 'desc') {
      arrayForSort.reverse();
    }
    this.searchResults = arrayForSort;
  }

  sortByRecordId(arrayForSort: SearchResult[]) {
    arrayForSort.sort((a, b) => {
      const records = this.getRecordsForSorting(a, b, true);

      return this.stringAlphabeticalCompare(records.a.id, records.b.id);
    });
    this.searchResults = arrayForSort;
  }

  onSort({ column, direction }: SortEvent): void {
    if (!this.searchResults) {
      return;
    }

    this.resetNonSelectedSortingDirections(column);

    const arrayForSort = [...this.searchResults];

    // Reset order if no direction is selected
    if (direction === '') {
      this.sortByRecordId(arrayForSort);
      return;
    }

    if (column?.length <= 0) {
      return;
    }

    if (column === 'ruleStatusActive') {
      this.sortByActiveStatus(arrayForSort, direction);
      return;
    }

    this.sortByAlphabeticalOrder(arrayForSort, column, direction);
  }

  entryToUpdateName() {
    if (this.entryToUpdate && getPropertyByKey(this.entryToUpdate, this.entryType)) {
      return this.entryToUpdateNameResolver(this.entryToUpdate);
    }

    return undefined;
  }

  showAlertOnCurrentRow(searchResult: any): boolean {
    if (this.entryType === EntryType.FARE) {
      return this.entryToUpdateName() === searchResult.id;
    }
    return this.entryToUpdateName() === searchResult.name;
  }

  getNameForConfirmationMessage(): string {
    if (this.entryType === EntryType.FARE) {
      return this.dynamicExclusionV2 ? 'Dynamic Exclusion Rule' : 'Fare Rule';
    }
    return this.entryToUpdateName() ?? '';
  }

  isShowEnables(): boolean {
    return !this.isLookup;
  }

  isModifyEnables(): boolean {
    return !this.isLookup;
  }

  isCopyEnables(): boolean {
    return !this.isLookup && !this.copyForbidden;
  }

  isDeleteEnables(): boolean {
    return !this.isLookup;
  }

  goBackLinkText(): string {
    switch (this.lookupOptions.sourceView) {
      case 'create':
        return $localize`:@@upp.global.button.returnCreate:Back to create`;
      case 'modify':
        return $localize`:@@upp.global.button.returnModify:Back to modify`;
      case 'search':
        return $localize`:@@upp.global.button.returnSearch:Back to search`;
      default:
        return '';
    }
  }

  goBack() {
    const lookupOptions: LookupOptions = {
      lookup: false,
      data: this.selectedNames ? Array.from(this.selectedNames) : undefined,
      sourceComponent: this.sourceComponent,
      destinationComponent: `${this.lookupOptions.sourceComponent}/${this.lookupOptions.sourceView}/`,
      fieldName: this.lookupOptions.fieldName,
      fieldPath: this.lookupOptions.fieldPath
    };

    this.selectEntries.emit(this.selectedNames);
    this.navigationService.navigate(lookupOptions);
  }

  selectAll(): void {
    this.selectAllChecked = !this.selectAllChecked;

    const allNames: (string | undefined)[] = this.filterSuccessRecords().map((r) => this.recordToSearchResult(r).name);
    if (this.selectAllChecked) {
      for (const name of allNames) {
        this.selectedNames.add(name);
      }
    } else {
      for (const name of allNames) {
        this.selectedNames.delete(name);
      }
    }
  }

  allSelected(): boolean {
    if (this.selectedNames && this.selectedNames.size > 0 && this.isSearchResultAvailable()) {
      const filtered = this.filterSuccessRecords();
      let allSelected = true;
      for (const result of filtered) {
        const name = this.recordToSearchResult(result).name;
        if (!name || !this.selectedNames.has(name)) {
          allSelected = false;
        }
      }
      this.selectAllChecked = allSelected;
    }
    return this.selectAllChecked;
  }

  handleInvalidInput(event: any) {
    if (isNaN(event.target.value) || parseInt(event.target.value, 10) < 1) {
      event.target.value = 10;
      this.pageSize = 10;
    }
  }

  hasManageAccess(record: any): boolean {
    const neededPermissions = [];

    let permissions;

    if (typeof this.managePermission === 'function') {
      permissions = this.managePermission(record);
    } else {
      permissions = this.managePermission;
    }

    if (Array.isArray(permissions)) {
      neededPermissions.push(...permissions);
    } else if (typeof permissions === 'string') {
      neededPermissions.push(permissions);
    }

    const userPermissions = this.userDetailsService.loggedInUser?.permissions ?? [];
    return neededPermissions.every((p) => userPermissions.includes(p));
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  protected setEntryToUpdateOnConfirmation(id: string, name: string, organization: string, version: number) {
    this.entryToUpdate = this.entryToUpdateCreator(id, name, organization, '1.0', version);
  }
}

export enum EntryType {
  POINT_OF_SALE = 'pointOfSale',
  MARKET = 'market',
  HOTEL_CHAINS_FAMILY = 'hotelChainsFamily',
  HOTEL_PROPERTY_CODES_FAMILY = 'hotelPropertyCodesFamily',
  JOURNEY = 'journey',
  RULE = 'rule',
  FARE = 'fare'
}

export interface SearchResult {
  id?: string;
  name?: string;
  description?: string;
  organization?: string;
  version?: number;
  statusType?: string;
  statusMessage?: string[];
  statusNotification?: UppNotification;
}

export interface DisplayableField {
  value: string;
  label: string;
  isSortable?: boolean;
}
