import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonGroupItem, hasActiveButtonItem } from '../../../../model/button-group-item';
import { ValueLabelItem } from '../../../../model/value-label-item';
import { UppComponentNames } from '../../../../service/model';
import {
  SECTION_VALIDATION_MESSAGES,
  TWO_SYMBOLS_REQUIRED
} from '../../../../service/model/common/validation-messages';
import { twoAlphaNumericRegex } from '../../../../service/model/common/validators';
import { CarrierDataUi, FlightServiceElements } from '../../../model';
import { carrierCodeValidator } from '../../validators/carrier-code-validator';
import { ConfigurationService } from '../../../../service/configuration/configuration.service';
import { FeatureFlags } from '../../../../core/util/resources';

@Component({
  selector: 'ama-ng-upp-airline-scope-selector',
  templateUrl: './airline-scope-selector.component.html'
})
export class AirlineScopeSelectorComponent implements OnChanges {
  @Input() formGroup: UntypedFormGroup;
  @Input() carrierData: CarrierDataUi;
  @Input() multiple = false;
  @Input() carrierCodeLabel = $localize`:@upp.global.criteria.carrierCode.label: Carrier code`;
  @Input() allianceLabel = $localize`:@upp.global.criteria.alliance.label: Alliance`;
  @Input() disabled = false;
  @Input() hasNDCContentType = true;

  CARRIER_CODE_TOOLTIP: string = $localize`:@@upp.flights.common.carrierCodeTooltip:Entering YY as carrier will add all carriers to the rule.`;

  availableAlliances: ValueLabelItem<string>[] = [{
      value: '*A',
      label: $localize`:@@upp.global.criteria.alliance.starAlliance:Star Alliance`
  }, {
      value: '*O',
      label: $localize`:@@upp.global.criteria.alliance.oneWorld:Oneworld`
  }, {
      value: '*S',
      label: $localize`:@@upp.global.criteria.alliance.skyteam:Skyteam`
    }];

  showFlightsYY = false;

  airlineScopeButtonGroupItems: ButtonGroupItem[];
  airlineScopeButtonGroupMap: { [key: string]: ButtonGroupItem };

  UppComponentNames = UppComponentNames;
  SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;
  carrierCodesValidationMessages = {
    ...TWO_SYMBOLS_REQUIRED,
    carrierCodeValidator: () =>
      $localize`:@@upp.validation.yyValueInCarrierCode:YY covers all airlines and cannot be combined with other airline codes`
  };

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configurationService: ConfigurationService
  ) {
    this.showFlightsYY = this.configurationService.getParameter<boolean>(FeatureFlags.flightsYY);
  }

  get hasSelected(): AbstractControl {
    return this.formGroup.get('hasSelected');
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.airlineScopeButtonGroupItems = this.generateAirlineScopeButtonGroupItems();
    this.airlineScopeButtonGroupMap = this.airlineScopeButtonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});

    this.formGroup.setControl('hasSelected', this.formBuilder.control(false, Validators.requiredTrue));

    if (this.carrierData?.carrierCodes || this.hasNDCContentType) {
      this.airlineScopeButtonGroupMap.carrierCodes.active = true;
      this.onAirlineScopeToggle(this.airlineScopeButtonGroupMap.carrierCodes, this.carrierData?.carrierCodes);
    } else if (this.carrierData?.alliances) {
      this.airlineScopeButtonGroupMap.alliances.active = true;
      this.onAirlineScopeToggle(this.airlineScopeButtonGroupMap.alliances, this.carrierData?.alliances);
    }
  }

  onAirlineScopeToggle(item: ButtonGroupItem, value?: string[] | string): void {
    this.airlineScopeButtonGroupItems
      .filter((buttonGroupItem) => buttonGroupItem !== item)
      .forEach((buttonGroupItem) => (buttonGroupItem.disabled = item.active ? true : this.disabled));

    if (item.active) {
      const validators = [Validators.required];
      if (item.name === FlightServiceElements.CARRIER_CODES) {
        validators.push(Validators.pattern(twoAlphaNumericRegex));
        if (this.showFlightsYY) {
          validators.push(carrierCodeValidator);
        }
      }
      this.formGroup.setControl(item.name, this.formBuilder.control({ value, disabled: this.disabled }, validators));
    } else {
      this.formGroup.removeControl(item.name);
    }

    this.hasSelected.setValue(hasActiveButtonItem(this.airlineScopeButtonGroupItems));
    this.hasSelected.markAsTouched();
    this.changeDetectorRef.detectChanges();
  }

  private generateAirlineScopeButtonGroupItems(): ButtonGroupItem[] {
    return [
      {
        name: FlightServiceElements.CARRIER_CODES,
        active: false,
        disabled: this.disabled,
        title: $localize`:@@upp.global.criteria.carrierCode.label:Carrier code`
      },
      {
        name: FlightServiceElements.ALLIANCES,
        active: false,
        disabled: this.disabled,
        title: $localize`:@@upp.global.criteria.alliance.label:Alliance`
      }
    ];
  }
}
