<form [formGroup]="marketDetailsForm" id="marketDetailsForm">
  <div class="mb-3">
    <div class="row mt-3">
      <div class="col text-end">
        <button
          i18n="@@upp.global.button.clear"
          *ngIf="!readonly"
          type="button"
          class="btn btn-outline-primary btn-sm"
          (click)="clearScreen()"
        >
          Clear
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col col-md-6 mb-5">
        <label class="label-font" for="name"
          ><ng-container i18n="@@upp.markets.display.nameLabel">Name</ng-container
          ><span *ngIf="!restrictInput()" class="mandatory">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          [readonly]="restrictInput()"
          [ngClass]="{ 'is-invalid': !marketDetailsForm.controls['name'].valid }"
          placeholder="Market name"
          i18n-placeholder="@@upp.markets.display.namePlaceholder"
          ngbTooltip="Enter a market name, max. 30 alpha numeric"
          i18n-ngbTooltip="@@upp.markets.display.nameTooltip"
          oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
        />
        <div *ngIf="!marketDetailsForm.controls['name'].valid" class="invalid mt-1">
          {{ getNameValidationError() }}
        </div>
      </div>
      <div class="col col-md-6 mb-5">
        <label i18n="@@upp.markets.display.descriptionLabel" class="label-font" for="description">Description</label>
        <input
          type="text"
          class="form-control"
          id="description"
          formControlName="description"
          [ngClass]="{ 'is-invalid': !marketDetailsForm.controls['description'].valid }"
          [readonly]="this.readonly"
          placeholder="Market description"
          i18n-placeholder="@@upp.markets.display.descriptionPlaceholder"
          ngbTooltip="Enter a market description, max. 128 alpha numeric"
          i18n-ngbTooltip="@@upp.markets.display.descriptionTooltip"
        />
        <div *ngIf="!marketDetailsForm.controls['description'].valid" class="invalid mt-1">
          <ng-container i18n="@@upp.validation.markets.numberOfCharacters"
            >Maximum/Minimum number of characters acceptable:</ng-container
          >
          {{ marketDetailsForm.controls['description'].errors.maxlength.requiredLength }}
        </div>
      </div>
    </div>
  </div>

  <h3 i18n="@@upp.markets.common.include" id="included">Include</h3>
  <div class="mb-2">
    <div formGroupName="include">
      <div class="row mb-2">
        <div class="col-2">
          <div class="form-check form-switch">
            <label class="form-check-label df-form-switch-name" for="worldToggle"
              >World
              <div class="df-form-switch-container">
                <input
                  class="form-check-input df-toggle-primary"
                  name="worldToggle"
                  type="checkbox"
                  id="worldToggle"
                  formControlName="world"
                  (click)="setWorldActivated()"
                />
                <span class="form-check-label">{{ marketsWorldActivated ? 'On' : 'Off' }}</span>
              </div>
            </label>
          </div>
        </div>
      </div>
      <ama-ng-upp-criteria-list
        [formGroup]="marketDetailsForm.get('include')"
        [readonly]="readonly"
        [criteriaList]="includeCriterias"
        [parent]="COMPONENT_NAME"
      >
      </ama-ng-upp-criteria-list>
    </div>
  </div>

  <h3 i18n="@@upp.markets.common.exclude" id="excluded">Exclude</h3>
  <div class="mb-4">
    <div formGroupName="exclude">
      <ama-ng-upp-criteria-list
        [formGroup]="marketDetailsForm.get('exclude')"
        [readonly]="readonly"
        [criteriaList]="excludeCriterias"
        [parent]="COMPONENT_NAME"
      >
      </ama-ng-upp-criteria-list>
    </div>
  </div>
</form>
