<div class="container-fluid">
  <div class="row">
    <div id="markets-search-title" class="col">
      <h1 i18n="@@upp.markets.search.title">Search Markets</h1>
    </div>
  </div>
  <div class="row">
    <div class="container-fluid">
      <ama-loading-alert
        [sendingStatus]="marketsDeleteSending$ | async"
        message="Loading..."
        i18n-message="@@upp.global.messages.loading"
      >
      </ama-loading-alert>
    </div>

    <ama-upp-notification
      id="markets-search-notification"
      class="col"
      [mainMessages]="mainMessages"
      [notification]="getNotification() | async"
      (closeNotificationEvent)="closeNotification()"
    >
    </ama-upp-notification>
  </div>
  <div class="row">
    <div id="search-criteria" class="col">
      <ama-ng-upp-markets-search-criteria
        [sendingStatus]="marketsSearchSending$ | async"
        (searchMarkets)="searchMarketsList($event)"
      >
      </ama-ng-upp-markets-search-criteria>
    </div>
  </div>
  <div class="row">
    <div id="search-result" class="col">
      <ama-ng-upp-basic-search-result-table
        [searchResults]="getSearchResults() | async"
        [selectedNames]="selectedMarketNames$ | async"
        [sendingStatus]="marketsDeleteSending$ | async"
        [isLookup]="lookup"
        [lookupValues]="lookupValues"
        [lookupOptions]="lookupOptions"
        [sourceComponent]="getSourceComponent()"
        [entryType]="getEntryType()"
        [recordToSearchResult]="recordToSearchResult"
        [entryToUpdateCreator]="entryToUpdateCreator"
        [entryToUpdateNameResolver]="entryToUpdateNameResolver"
        (selectEntry)="selectEntries($event)"
        (deleteEntry)="deleteMarketsEntry($event)"
        (clearTableEvent)="clearMarketsResults()"
        (showDetail)="showMarketsDetail($event)"
        (modifyDetail)="modifyMarketsDetail($event)"
        (copyDetail)="copyMarketsDetail($event)"
        [managePermission]="managePermission"
        [displayableFields]="displayableFields"
      >
      </ama-ng-upp-basic-search-result-table>
    </div>
  </div>
</div>
