import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ALL_DEFAULT_MESSAGES } from '../../service/model/common/validation-messages';
import { Subscription } from 'rxjs';


@Component({
  selector: 'ama-ng-upp-validation-error-message',
  templateUrl: './validation-error-message.component.html'
})
export class ValidationErrorMessageComponent implements OnInit, OnDestroy {
  @Input() control!: AbstractControl;
  /**
   * Map with localization function which returns a message string for each validation type (key).
   * E.g. { maxlength: (params) => $localize`:@@upp.validation.maxlength:Maximum allowed length: ` + params.requiredLength }
   * Message function suppliled this way will override default message functions for given validation types.
   */
  @Input() messageFunctions!: { [key: string]: (params?: any) => string };

  /**
   * If set to false it will show all validation errors below each other. If true it will show only the first (be default).
   */
  @Input() showFirstOnly = true;

  messagesMap!: { [key: string]: (params?: any) => string };
  messages!: string[];

  subscription!: Subscription;

  DEFAULT_ERROR: string = $localize`:@@upp.validation.invalidFormat:Invalid format`;

  ngOnInit(): void {
    if (this.messageFunctions) {
      this.messagesMap = { ...ALL_DEFAULT_MESSAGES, ...this.messageFunctions };
    } else {
      this.messagesMap = { ...ALL_DEFAULT_MESSAGES };
    }

    this.subscription = this.control.statusChanges.subscribe(() => {
      this.evaluateErrorMessages();
    });
    this.evaluateErrorMessages();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private evaluateErrorMessages(): void {
    this.messages = [];
    let errorKeys = this.control?.errors && Object.keys(this.control.errors);
    if (this.showFirstOnly && errorKeys?.length && errorKeys?.length > 0) {
      errorKeys = [errorKeys[0]];
    }

    this.messages = errorKeys?.map(
      (errorKey) => this.messagesMap[errorKey]?.(this.control.errors?.[errorKey]) ?? this.DEFAULT_ERROR
    ) ?? [];
  }
}
