import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppNotification } from '../../model/notification';
import { UserDetailsService } from '../../service/user-details.service';
import {
  CreateFamilyHotelChainsAction,
  CreateFamilyPropertyCodesAction,
  DeleteFamilyCreateNotificationAction,
  SetCreateFamilyValueAction,
  ActivateCreatedFamilyDisplayAction
} from '../../store/families/families-action';
import {
  selectFamilyCreateValue,
  selectFamilyRuleCreateNotification,
  selectFamilyRuleCreateSending
} from '../../store/families/families-selector';
import {
  convertFamilyUiToHotelChainsFamily,
  convertFamilyUiToHotelPropertyCodesFamily,
  FamilyServiceElements,
  FamilyUi, familyUiEquals,
  initialFamilyUi,
  HotelChainsCreateRequest,
  HotelPropertyCodesCreateRequest
} from '../model';
import { UppComponent } from '../../service/model';
import { NavigationService } from '../../service/core/navigation.service';
import { validateFamily } from '../model/families-validator';
import { FormComponent } from '../../model/FormComponent';

@Component({
  selector: 'ama-ng-upp-families-create',
  templateUrl: './families-create.component.html'
})
export class FamiliesCreateComponent implements OnInit, FormComponent {
  
  notification$!: Observable<UppNotification | undefined>;
  mainMessages: NotificationMessages;

  familyUi: FamilyUi = initialFamilyUi;
  sendingStatus!: boolean;
  readonly = false;
  familyForm!: UntypedFormGroup;

  uppComponent = UppComponent.FAMILIES_CREATE;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService,
    private readonly router: Router,
    private readonly store: Store<any>
  ) {
    this.notification$ = this.store.pipe(select(selectFamilyRuleCreateNotification));
    this.store.pipe(select(selectFamilyRuleCreateSending)).subscribe((sending) => (this.sendingStatus = sending));
    this.mainMessages = {
      error: $localize`:@@upp.families.create.mainErrorText:Creation of family failed due to the following errors:`,
      warning: $localize`:@@upp.families.create.mainWarningText:Warnings were generated during the creation process of family:`,
      success: $localize`:@@upp.families.create.mainSuccessText:Well done! You get this message about your successful Family creation`,
      linkSuccessText: $localize`:@@upp.families.create.linkSuccessText:Display new Family: `
    };
  }

  ngOnInit(): void {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.flights.create.navigationTitle:Flight Rule Creation`);
    this.createAndLoadForm();
  }

  createAndLoadForm() {
    this.familyForm = this.formBuilder.group({});
    this.store.pipe(select(selectFamilyCreateValue)).subscribe((family) => {
      if (family) {
        this.familyUi = family;
        this.familyForm.reset();
      }
    });
  }

  closeNotification() {
    this.store.dispatch(new DeleteFamilyCreateNotificationAction({}));
    this.notification$ = this.store.pipe(select(selectFamilyRuleCreateNotification));
  }

  showFamilyInDisplay() {
    this.store.dispatch(new ActivateCreatedFamilyDisplayAction({}));
    this.router.navigate(['families/display']);
  }

  saveButtonEnabled(): boolean {
    if (!this.familyForm) {
      return false;
    }
    return validateFamily(this.familyForm);
  }

  saveData() {
    const chainsChosen = this.familyForm.get(FamilyServiceElements.HOTEL_TYPE)?.value === FamilyServiceElements.CHAINS;
    this.notification$ = this.store.pipe(select(selectFamilyRuleCreateNotification));
    if (chainsChosen) {
      const request = this.createHotelChainsRequest();
      request.hotelChainsFamily = this.userDetailsService.assignEntity(request.hotelChainsFamily);
      this.store.dispatch(new CreateFamilyHotelChainsAction({ request }));
    } else {
      const request = this.createPropertyCodesRequest();
      request.hotelPropertyCodesFamily = this.userDetailsService.assignEntity(request.hotelPropertyCodesFamily);
      this.store.dispatch(new CreateFamilyPropertyCodesAction({ request }));
    }

    this.storeUnsavedChanges();
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetCreateFamilyValueAction({ familyUi: this.familyForm?.value }));
  }

  hasUnsavedChanges(): boolean {
    return !familyUiEquals(initialFamilyUi, this.familyForm?.value as FamilyUi);
  }

  private createHotelChainsRequest(): HotelChainsCreateRequest {
    return {
      version: '1.0',
      hotelChainsFamily: convertFamilyUiToHotelChainsFamily(this.familyForm?.value)
    };
  }

  private createPropertyCodesRequest(): HotelPropertyCodesCreateRequest {
    return {
      version: '1.0',
      hotelPropertyCodesFamily: convertFamilyUiToHotelPropertyCodesFamily(this.familyForm?.value)
    };
  }
}
