<div>
  <div class="d-flex justify-content-end mb-5">
    @if (!readonly) {
    <button type="button" class="btn btn-outline-primary btn-sm" (click)="clearScreen()"
      i18n="@@upp.global.button.clear">
      Clear
    </button>
    }
  </div>

  @if (fareDetailsForm) {
  <form class="styled-form" [formGroup]="fareDetailsForm">
    <div class="mb-5 form-check form-switch">
      <label class="form-check-label df-form-switch-name" for="activationToggle">
        <ng-container i18n="@@upp.fares.details.activateRuleLabel">Activate Rule</ng-container>
        <div class="df-form-switch-container">
          <input class="form-check-input" name="activationToggle" type="checkbox" id="activationToggle"
            formControlName="active" [readonly]="readonly">
          <span class="form-check-label">{{ active.value ? 'On' : 'Off' }}</span>
        </div>
      </label>
    </div>

    <div formGroupName="criteria">
      <div class="mb-5">
        <label for="name" class="form-label">
          <ng-container i18n="@@upp.fares.details.nameLabel">Name</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <input type="text" class="form-control" id="name" formControlName="name" [readonly]="readonly"
          [ngClass]="{ 'is-invalid': nameControl.invalid && nameControl.touched }"
          placeholder="Dynamic exclusion rule name"
          i18n-placeholder="@@upp.dynamicExclusions.common.namePlaceholder"
          ngbTooltip="Enter a dynamic exclusion name, max. 30 alpha numeric without spaces"
          i18n-ngbTooltip="@@upp.dynamicExclusions.common.nameTooltip"
          oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);" />
        <ama-ng-upp-validation-error-message [control]="nameControl"
          [messageFunctions]="NAME_WITH_SPACES_VALIDATION_MESSAGES"></ama-ng-upp-validation-error-message>
      </div>

      <div class="mb-5">
        <label for="description" class="form-label" i18n="@@upp.fares.details.descriptionLabel">Description</label>
        <input type="text" class="form-control" id="description" formControlName="description" [readonly]="readonly"
          placeholder="Dynamic exclusion rule description"
          i18n-placeholder="@@upp.dynamicExclusions.details.descriptionPlaceholder"
          ngbTooltip="Enter a description for this dynamic exclusion rule, max. 128 alpha numeric"
          i18n-ngbTooltip="@@upp.dynamicExclusions.details.descriptionTooltip" />
        <ama-ng-upp-validation-error-message [control]="descriptionControl"
          [messageFunctions]="MAX_LENGTH_STRING_VALIDATION_MESSAGES"></ama-ng-upp-validation-error-message>
      </div>

      <div class="mb-5">
        <label for="pointOfSaleName" class="form-label">
          <ng-container i18n="@@upp.global.criteria.pointOfSale.label">Point of sale</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="pointOfSaleName" formControlName="pointOfSale" [items]="availablePosNames"
          [lookupOptions]="readonly ? null : posLookupOptions" notFoundText="No POS found"
          i18n-notFoundText="@@upp.global.criteria.pos.notFoundText">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="fareDetailsForm.get('criteria.pointOfSale')">
        </ama-ng-upp-validation-error-message>
      </div>

      <div formGroupName="marketPair" class="d-flex align-items-start mb-5">
        <div class="flex-grow-1 flex-basis-100">
          <label for="firstMarketName" class="form-label">
            <ng-container i18n="@@upp.global.criteria.firstMarket.label">First market</ng-container>
            <span class="mandatory ms-1">*</span>
          </label>
          <ama-ng-upp-lookup-select id="firstMarketName" formControlName="firstMarketName"
            [items]="availableMarketsNames" [lookupOptions]="readonly ? null : firstMarketLookupOptions"
            notFoundText="No markets found" i18n-notFoundText="@@upp.global.criteria.markets.notFoundText">
          </ama-ng-upp-lookup-select>
          <ama-ng-upp-validation-error-message [control]="fareDetailsForm.get('criteria.marketPair.firstMarketName')">
          </ama-ng-upp-validation-error-message>
        </div>

        <div class="flex-grow-1 flex-basis-100 ms-5">
          <label for="secondMarketName" class="form-label">
            <ng-container i18n="@@upp.global.criteria.secondMarket.label">Second market</ng-container>
            <span class="mandatory ms-1">*</span>
          </label>
          <ama-ng-upp-lookup-select id="secondMarketName" formControlName="secondMarketName"
            [items]="availableMarketsNames" [lookupOptions]="readonly ? null : secondMarketLookupOptions"
            notFoundText="No markets found" i18n-notFoundText="@@upp.global.criteria.markets.notFoundText">
          </ama-ng-upp-lookup-select>
          <ama-ng-upp-validation-error-message [control]="fareDetailsForm.get('criteria.marketPair.secondMarketName')">
          </ama-ng-upp-validation-error-message>
        </div>
      </div>
    </div>

    <div formGroupName="reference" class="reference-content-container">
      <label for="edifactCriteria" class="d-block mb-3" i18n="@@upp.fares.details.DefineEdifactReferenceCriteriaLabel">
        Define the EDIFACT reference criteria
      </label>
      <div class="mb-5">
        <ama-ng-upp-button-group [items]="edifactReferenceCriteriaButtonGroupItems"
          (toggled)="onEdifactReferenceCriteriaToggle($event)">
        </ama-ng-upp-button-group>
      </div>

      @if (edifactReferenceCriteriaButtonGroupMap.carrier.active) {
      <div class="mb-5">
        <label class="form-label" for="carrierCodes">
          <ng-container i18n="@@upp.global.criteria.carrierCode.label">Carrier code</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="carrierCodes" formControlName="carrierCodes" multiple="true" addTag="true"
          notFoundText="No airlines found" i18n-notFoundText="@@upp.global.criteria.carrierCode.notFoundText">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="fareDetailsForm.get('reference.carrierCodes')"
          [messageFunctions]="TWO_SYMBOLS_REQUIRED"></ama-ng-upp-validation-error-message>
      </div>
      }

      @if (edifactReferenceCriteriaButtonGroupMap.cabin.active) {
      <div class="mb-5">
        <label class="form-label" for="cabin">
          <ng-container i18n="@@upp.fares.details.cabins.label">Cabin</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="cabin" formControlName="cabin" [items]="cabinOptions"
          bindValue="value" bindLabel="label">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message
          [control]="fareDetailsForm.get('reference.cabin')"></ama-ng-upp-validation-error-message>
      </div>
      }

      @if (edifactReferenceCriteriaButtonGroupMap.fareTypes.active) {
      <div class="mb-5">
        <label class="form-label" for="fareTypes">
          <ng-container i18n="@@upp.fares.details.fareTypes.label">Fare types</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="fareTypes" formControlName="fareTypes" multiple="true" [items]="fareTypesOptions"
          notFoundText="No fare type found" i18n-notFoundText="@@upp.fares.details.fareTypeNotFound"
          bindValue="value" bindLabel="label">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message
          [control]="fareDetailsForm.get('reference.fareTypes')"></ama-ng-upp-validation-error-message>
      </div>
      }
    </div>

    <div formGroupName="exclusion" class="exclusion-content-container">
      <label for="ndcCriteria" class="d-block mb-3 mt-5" i18n="@@upp.fares.details.DefineNdcExclusionCriteriaLabel">
        Define the NDC exclusion criteria
      </label>

      <div class="mb-5" role="radiogroup" aria-labelledby="farePriceRange">
        <label for="priceRange" class="d-block mb-3" id="farePriceRange">
          <ng-container i18n="@@upp.fares.details.PriceDifferenceLabel">Price Range</ng-container>
          <span class="icon-info-circle ms-3 me-3" [ngbTooltip]="priceRangeTooltip"></span>
        </label>
        <ng-template #priceRangeTooltip>
          <div class="d-flex flex-column">
            <span i18n="@@upp.fares.details.priceRange.tooltip.title">The range of results to exclude (as an amount or a
              percentage) compared to the fare price. For example: </span>
            <br />
            <span i18n="@@upp.fares.details.priceRange.tooltip.farePrice">Fare price: 100</span>
            <span i18n="@@upp.fares.details.priceRange.tooltip.lower">Lower limit: -30</span>
            <span i18n="@@upp.fares.details.priceRange.tooltip.upper">Upper limit: +30</span>
            <br />
            <span i18n="@@upp.fares.details.priceRange.tooltip.result">
              Results will exclude fares with prices between 70 and 130.
            </span>
          </div>
        </ng-template>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="priceRangeTypeRate" value="R"
            formControlName="priceRangeType" />
          <label i18n="@@upp.fares.details.priceRangeTypeRateLabel" class="form-check-label"
            for="priceRangeTypeRate">Percentage</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="priceRangeTypeFlat" value="F"
            formControlName="priceRangeType" />
          <label i18n="@@upp.fares.details.priceRangeTypeFlatLabel" class="form-check-label"
            for="priceRangeTypeFlat">Amount</label>
        </div>
      </div>

      <div class="d-flex align-items-start mb-5">
        <div class="flex-grow-1 flex-basis-100">
          <label for="priceRangeMin" class="form-label">
            <ng-container i18n="@@upp.fares.details.priceRangeMin">Lower limit</ng-container>
            <span class="mandatory ms-1">*</span>
          </label>
          <input type="number" class="form-control" id="priceRangeMin" formControlName="priceRangeMin"
            [readonly]="readonly"
            [ngClass]="{ 'is-invalid': !priceRangeMinControl.valid && priceRangeMinControl.touched }" />
          <ama-ng-upp-validation-error-message [control]="priceRangeMinControl"
            [messageFunctions]="priceRangeMinValidationMessageFunctions">
          </ama-ng-upp-validation-error-message>
        </div>

        <div class="flex-grow-1 flex-basis-100 ms-5">
          <label for="priceRangeMax" class="form-label">
            <ng-container i18n="@@upp.fares.details.priceRangeMax">Upper limit</ng-container>
            <span class="mandatory ms-1">*</span>
          </label>
          <input type="number" class="form-control" id="priceRangeMax" formControlName="priceRangeMax"
            [readonly]="readonly"
            [ngClass]="{ 'is-invalid': !priceRangeMaxControl.valid && priceRangeMaxControl.touched }" />
          <ama-ng-upp-validation-error-message [control]="priceRangeMaxControl"
            [messageFunctions]="priceRangeMaxValidationMessageFunctions">
          </ama-ng-upp-validation-error-message>
        </div>
      </div>

      <div class="mb-5">
        <ama-ng-upp-button-group [items]="ndcExclusionCriteriaButtonGroupItems"
          (toggled)="onNdcExclusionCriteriaToggle($event)">
        </ama-ng-upp-button-group>
      </div>

      @if (ndcExclusionCriteriaButtonGroupMap.carrier.active) {
      <div class="mb-5">
        <label class="form-label" for="carrierCodes">
          <ng-container>Carrier codes</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="carrierCodes" formControlName="carrierCodes" multiple="true" addTag="true"
          notFoundText="No airlines found" i18n-notFoundText="@@upp.global.criteria.carrierCode.notFoundText">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="fareDetailsForm.get('exclusion.carrierCodes')"
          [messageFunctions]="TWO_SYMBOLS_REQUIRED"></ama-ng-upp-validation-error-message>
      </div>
      }

      @if (ndcExclusionCriteriaButtonGroupMap.departureTimeRange.active) {
      <label class="d-block mb-3" id="departureTimeRange">
        <ng-container i18n="@@upp.fares.details.departureTimeRange.label">Departure time range</ng-container>
        <span class="icon-info-circle ms-3 me-3" [ngbTooltip]="departureTimeRangeTooltip"></span>
      </label>
      <ng-template #departureTimeRangeTooltip>
        <div class="d-flex flex-column">
          <span i18n="@@upp.fares.details.departureTimeRange.tooltip.title"> The time range to exclude (in
            minutes) compared to the departure time. For example: </span>
          <br />
          <span i18n="@@upp.fares.details.departureTimeRange.tooltip.departureTime">Departure time: 10:00</span>
          <span i18n="@@upp.fares.details.departureTimeRange.tooltip.earliest">Earliest time: -30</span>
          <span i18n="@@upp.fares.details.departureTimeRange.tooltip.latest">Latest time: +30</span>
          <br />
          <span i18n="@@upp.fares.details.departureTimeRange.tooltip.result"> Results will exclude fares with
            departure times between 09:30 and 10:30. Positive and negative values are allowed. </span>
        </div>
      </ng-template>

      <div class="d-flex align-items-start mb-5">
        <div class="flex-grow-1 flex-basis-100">
          <label for="departureTimeWindowMin" class="form-label">
            <ng-container i18n="@@upp.fares.details.departureTimeWindowMin">Lower limit</ng-container>
            <span class="mandatory ms-1">*</span>
          </label>
          <input type="number" class="form-control" id="departureTimeWindowMin" formControlName="departureTimeWindowMin"
            [readonly]="readonly"
            [ngClass]="{'is-invalid': !departureTimeWindowMinControl.valid && departureTimeWindowMinControl.touched}"/>
          <ama-ng-upp-validation-error-message [control]="departureTimeWindowMinControl"
            [messageFunctions]="departureTimeWindowMessageFunctions">
          </ama-ng-upp-validation-error-message>
        </div>

        <div class="flex-grow-1 flex-basis-100 ms-5">
          <label for="departureTimeWindowMax" class="form-label">
            <ng-container i18n="@@upp.fares.details.departureTimeWindowMax">Upper limit</ng-container>
            <span class="mandatory ms-1">*</span>
          </label>
          <input type="number" class="form-control" id="departureTimeWindowMax" formControlName="departureTimeWindowMax"
            [readonly]="readonly"
            [ngClass]="{'is-invalid': !departureTimeWindowMaxControl.valid && departureTimeWindowMaxControl.touched}"/>
          <ama-ng-upp-validation-error-message [control]="departureTimeWindowMaxControl"
            [messageFunctions]="departureTimeWindowMessageFunctions">
          </ama-ng-upp-validation-error-message>
        </div>
      </div>
      }

      @if (ndcExclusionCriteriaButtonGroupMap.fareTypes.active) {
      <div class="mb-5">
        <label class="form-label" for="fareTypes">
          <ng-container i18n="@@upp.fares.details.fareTypes.label">Fare types</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="fareTypes" formControlName="fareTypes" multiple="true" [items]="fareTypesOptions"
          notFoundText="No fare type found" i18n-notFoundText="@@upp.fares.details.fareTypeNotFound"
          bindValue="value" bindLabel="label">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message
          [control]="fareDetailsForm.get('exclusion.fareTypes')"></ama-ng-upp-validation-error-message>
      </div>
      }

      @if (ndcExclusionCriteriaButtonGroupMap.journeyTypes.active) {
      <div class="mb-5">
        <label for="flightCategories" class="form-label">
          <ng-container i18n="@@upp.fares.details.journeyTypes.label">Journey type</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="journeyTypes" formControlName="journeyTypes" groupBy="parent"
          [groupValue]="journeyTypesGroupValueFn" selectableGroup="true" [items]="journeyTypesOptions" bindValue="value"
          notFoundText="No journey type found" i18n-notFoundText="@@upp.fares.details.journeyTypeNotFound"
          bindLabel="label" multiple="true">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="fareDetailsForm.get('exclusion.journeyTypes')">
        </ama-ng-upp-validation-error-message>
      </div>
      }
    </div>
  </form>
  }
</div>
