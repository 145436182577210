import { BaseUi } from '../../base/model/base-ui';
import { Location } from './flight-rule';
import { arraysEquals } from '../../util/arrayUtils';
import { ConnectionType, ContentType, JourneyContentAction, JourneyFare, JourneyFlightCategory, Product } from './journey';
import { MarketPair } from '../../model/market-pair';

export const INCLUDE_ACTION = 'include';
export const EXCLUDE_ACTION = 'exclude';
export const ALL_ACTION = 'all';
export const RULE_STATUS_ALL = 'all';
export const RULE_STATUS_ACTIVE = 'active';
export const RULE_STATUS_INACTIVE = 'inactive';

export interface FlightRuleUi {
  id?: string;
  version?: number | string;
  organization: string;
  name: string;
  description?: string;
  active: boolean;
  action: string;
}

export interface FlightApplicabilityUi {
  pointOfSales?: Location[];
  pointOfSaleNames?: string[];
  origins?: Location[];
  originNames?: string[];
  destinations?: Location[];
  destinationNames?: string[];
  carriers?: string[];
}

export interface JourneyUi extends BaseUi {
  rule: Rule;
  applicability: Applicability;
  content?: RuleContent;
}

export interface JourneyUiSubRule {
  hasRuleContent?: boolean;
  hasNoJourneyWithoutCarrier?: boolean;
  applicability: {
    carrier?: JourneyUiCarrierScope;
    route?: JourneyUiRouteScope;
    journey?: JourneyUiJourneyScope;
    fare?: JourneyFare;
  };
  content: RuleActionValue;
}

interface Rule {
  active: boolean;
  name: string;
  description?: string;
  id?: string;
  version?: number | string;
  organization?: string;
  action?: string;
}

export interface Applicability {
  pointOfSaleName: string;
  marketPairs?: MarketPair[];
  contentTypes?: ContentType[];
  products?: Product[];
}

export interface RuleContent {
  subRules: JourneyUiSubRule[];
}

export interface JourneyUiCarrierScope {
  carrierInformations?:  CarrierDataUi;
  codeshareInformations?: CodeshareInformationsUi;
  flightRanges?: string[];
  connectionType?: ConnectionType;
  segmentPositions?: string[];
}

export interface CarrierDataUi {
  carrierCodes?: string[] | string;
  alliances?: string[] | string;
}

export interface JourneyUiRouteScope {
  markets: JourneyUiRouteMarket[];
}

export interface JourneyUiRouteMarket {
  name: string;
  position?: number | string;
}

export interface JourneyUiJourneyScope {
  flightCategories: JourneyFlightCategory[];
}

export interface RuleActionValue {
  action?: JourneyContentAction;
  value?: number;
}

export const initialJourneyUiRouteScope: JourneyUiRouteScope = {
  markets: []
};

export const initialJourneyUiJourneyScope: JourneyUiJourneyScope = {
  flightCategories: []
};

export const initialJourneyUiRouteMarket: JourneyUiRouteMarket = {
  name: null,
  position: null
};

export interface CodeshareInformationsUi {
  primeData?:  CarrierDataUi;
  operatingCarrierData?:  CarrierDataUi;
  marketingCarrierData?: CarrierDataUi;
}

export const initialJourneyUi: JourneyUi = {
  rule: {
    name: '',
    description: null,
    active: false
  },
  applicability: {
    pointOfSaleName: null
  },
  content: {
    subRules: []
  }
};

export const journeyUiEquals = (j1: JourneyUi, j2: JourneyUi): boolean => {
  if (j1 === j2) {
    return true;
  }

  if (j1 === undefined || j2 === undefined) {
    return false;
  }

  // TODO: Add comparison for content
  return flightRuleUiEquals(j1.rule, j2.rule) &&
    flightApplicabilityUiEquals(j1.applicability, j2.applicability) &&
    flightContentUiEquals(j1.content, j2.content);
};

const flightRuleUiEquals = (f1: Rule, f2: Rule): boolean => {
  if (f1 === f2) {
    return true;
  }
  if (f1 === undefined || f2 === undefined) {
    return false;
  }
  return (
    f1.id === f2.id &&
    f1.version === f2.version &&
    f1.organization === f2.organization &&
    f1.name === f2.name &&
    f1.description === f2.description &&
    f1.active === f2.active
  );
};

const flightContentUiEquals = (f1: RuleContent, f2: RuleContent): boolean => {
  if (f1 === f2) {
    return true;
  }
  if (f1 === undefined || f2 === undefined) {
    return false;
  }

  return JSON.stringify(f1) === JSON.stringify(f2);
};

const flightApplicabilityUiEquals = (f1: Applicability, f2: Applicability): boolean => {
  if (f1 === f2) {
    return true;
  }
  if (f1 === undefined || f2 === undefined) {
    return false;
  }
  return f1.pointOfSaleName === f2.pointOfSaleName &&
      arraysEquals(f1.marketPairs, f2.marketPairs, marketPairEquals);
};

const marketPairEquals = (mp1: MarketPair, mp2: MarketPair): boolean => (
  mp1.firstMarketName === mp2.firstMarketName &&
  mp1.secondMarketName === mp2.secondMarketName
);
