import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { SelectableCriteriaWithParams } from '../../../base/search-criteria/selectable-criteria';

@Component({
  selector: 'ama-ng-upp-criteria-list',
  templateUrl: './criteria-list.component.html',
  styleUrls: ['./criteria-list.component.scss']
})
export class CriteriaListComponent {
  @Input() formGroup!: UntypedFormGroup;
  @Input() readonly!: boolean;
  @Input() criteriaList!: SelectableCriteriaWithParams[];
  @Input() largeButtons = false;
  @Output() customCriteriaClicked = new EventEmitter<string>();
  // large default maxPerRow - rows break at end of page if no arg is passed
  @Input() maxPerRow = 10;
  @Input() parent!: string;

  constructor() {}

  toggleActive(criteria: SelectableCriteriaWithParams) {
    if (criteria.customInputField) {
      this.customCriteriaClicked.emit(criteria.name);
    } else {
      if (criteria.active) {
        if (!this.checkContentToBeEmpty(this.formGroup.get(criteria.name))) {
          return;
        }
      }
      criteria.active = !criteria.active;
    }
  }

  checkContentToBeEmpty(control: AbstractControl | null): boolean {
    if (!control) {
      return false;
    }
    if (control.value === undefined || control.value === null
      || control.value === '' || control.value.length === 0) {
      return true;
    }
    return false;
  }

  customClicked(criteriaName: any) {
    this.customCriteriaClicked.emit(criteriaName);
  }

  shouldAddLineBreak(i: number): boolean {
    return (i + 1) % this.maxPerRow === 0;
  }

  onRemoveBadge(value: string, criteria: SelectableCriteriaWithParams) {
    criteria.badgeItems = criteria.badgeItems.filter((item: any) => item !== value);
  }
}
