<form [formGroup]="fareDetailsForm" id="fareDetailsForm">
  <div formGroupName="rule">
    <div class="mb-3">
      <div class="row mt-3">
        <div class="col text-end">
          <button
            i18n="@@upp.global.button.clear"
            *ngIf="!readonly"
            type="button"
            class="btn btn-outline-primary btn-sm"
            (click)="clearScreen()"> Clear </button>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-2">
          <div class="form-check form-switch">
            <label class="form-check-label df-form-switch-name" for="activationToggle"
              >Activate Rule
              <div class="df-form-switch-container">
                <input
                  class="form-check-input df-toggle-primary"
                  name="activationToggle"
                  type="checkbox"
                  id="activationToggle"
                  formControlName="active"
                  [readonly]="readonly"
                />
                <span class="form-check-label">{{ isRuleActivated() ? 'On' : 'Off' }}</span>
              </div>
            </label>
          </div>
        </div>
        <div class="col-10"></div>
      </div>

      <div class="row mt-3">
        <div class="col mb-5">
          <label class="label-font" for="name">
            <ng-container
              i18n="@@upp.fares.details.nameLabel">Name</ng-container><span
              class="mandatory">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            formControlName="name"
            readonly="true"
            [ngClass]="{ 'is-invalid': !this.isNameControlValid() }"
            placeholder="Fare rule name"
            i18n-placeholder="@@upp.fares.common.namePlaceholder"
            ngbTooltip="Enter a fare rule name, max. 30 alpha numeric without spaces"
            i18n-ngbTooltip="@@upp.fares.common.nameTooltip"
            oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p,p);" />
          <div *ngIf="!this.isNameControlValid()" class="invalid mt-1"> {{ getNameValidationError() }} </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col form-group">
          <label i18n="@@upp.fares.details.descriptionLabel" class="label-font"
            for="description">Description</label>
          <input
            type="text"
            class="form-control"
            id="description"
            formControlName="description"
            [ngClass]="{ 'is-invalid': !isDescriptionControlValid() }"
            readonly="true"
            placeholder="Fare rule description"
            i18n-placeholder="@@upp.fares.details.descriptionPlaceholder"
            ngbTooltip="Enter a description for this fare rule, max. 128 alpha numeric"
            i18n-ngbTooltip="@@upp.fares.details.descriptionTooltip" />
          <div *ngIf="!isDescriptionControlValid()" class="invalid mt-1"> {{ getDescriptionValidationError() }} </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col"> {{ getReferenceCriteriaTitle() }} </div>
      </div>
      <div [formGroup]="fareDetailsForm.get(elements.REFERENCE)">
        <ama-ng-upp-criteria-list
          [formGroup]="fareDetailsForm.get(elements.REFERENCE)"
          [readonly]="readonly"
          [criteriaList]="referenceCriteria"
          [largeButtons]="true"
          [maxPerRow]="3"
          (customCriteriaClicked)="toggleCustomReferenceCriteria($event)"
          [parent]="COMPONENT_NAME">
        </ama-ng-upp-criteria-list>
        <ng-template #cabinsTemplate>
          <label i18n="@@upp.fares.details.cabins.label" for="cabinSelect" class="label-font">Cabin</label>
          <ng-select
            class="mb-4"
            labelForId="cabinSelect"
            [selectOnTab]="true"
            name="cabinSelect"
            [items]="cabinOptions"
            [searchable]="false"
            [clearable]="true"
            formControlName="cabins"
            ngbTooltip="Select cabin type"
            i18n-ngbTooltip="@@upp.fares.details.cabinsTooltip"
            placeholder="Cabin type"
            i18n-placeholder="@@upp.fares.details.cabinsPlaceholder"></ng-select>
        </ng-template>
      </div>
      <div class="row mb-3">
        <div class="col"> {{ getExclusionCriteriaTitle() }} </div>
      </div>
      <div [formGroup]="fareDetailsForm.get(elements.EXCLUSION)">
        <ama-ng-upp-criteria-list
          [formGroup]="fareDetailsForm.get(elements.EXCLUSION)"
          [readonly]="readonly"
          [criteriaList]="exclusionCriteria"
          [largeButtons]="true"
          [maxPerRow]="3"
          (customCriteriaClicked)="toggleCustomExclusionCriteria($event)"
          [parent]="COMPONENT_NAME">
        </ama-ng-upp-criteria-list>
        <ng-template #serviceTypesTemplate>
          <ama-ng-upp-multi-select-badges
            formElementName="serviceTypes"
            formElementLabel="Service Types"
            i18n-formElementLabel="@@upp.global.criteria.serviceTypes.label"
            [formGroup]="fareDetailsForm.get(elements.EXCLUSION)"
            placeholder="Service types"
            i18n-placeholder="@@upp.global.criteria.serviceTypes.placeholder"
            numberOfBadges="100"
            [readonlyMode]="readonly"
            [badgeItems]="getExclusionCriteria('serviceTypes').badgeItems"
            notFoundText="No service type found"
            i18n-notFoundText="@@upp.global.criteria.serviceTypes.notFoundText"
            tooltipText="Select one or more service types"
            i18n-tooltipText="@@upp.global.criteria.serviceTypes.tooltip"
            [availableItems]="serviceTypeOptions"
            disableNewValues="true">
          </ama-ng-upp-multi-select-badges>
          <label i18n="@@upp.fares.details.numberOfConnections.label" class="form-label">Number of Connections</label>
          <input
            type="number"
            class="form-control"
            min="0"
            id="numberOfConnections"
            [ngClass]="{ 'is-invalid': !isNumberOfConnectionsValid() }"
            placeholder="Connections"
            i18n-placeholder="@@upp.fares.details.numberOfConnections.placeholder"
            ngbTooltip="Select or enter the number of connections"
            i18n-ngbTooltip="@@upp.fares.details.numberOfConnections.tooltip"
            [readonly]="!shouldEnableNumberOfConnections"
            [formControlName]="elements.NUMBER_OF_CONNECTIONS"
            name="elements.NUMBER_OF_CONNECTIONS" />
          <div *ngIf="!isNumberOfConnectionsValid()" class="invalid mt-1"> {{ getNumberConnectionsValidationError() }} </div>
        </ng-template>
        <ng-template #contentTemplate>
          <div role="radiogroup" aria-labelledby="radio-group-1" aria-labelledby="fareContentOptions">
            <span i18n="@@upp.fares.details.contentOptionsContentLabel" class="label-font" id="fareContentOptions">Content</span>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="contentOptionsEdifact"
                disabled="true"
                [value]="elements.CONTENT_OPTIONS_EDIFACT"
                [name]="elements.CONTENT_OPTIONS"
                [formControlName]="elements.CONTENT_OPTIONS"/>
              <label i18n="@@upp.fares.details.contentOptionsEdifactLabel" class="form-check-label"
                for="contentOptionsEdifact"> EDIFACT </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="contentOptionsNDC"
                [value]="elements.CONTENT_OPTIONS_NDC"
                [name]="elements.CONTENT_OPTIONS"
                [formControlName]="elements.CONTENT_OPTIONS"/>
              <label i18n="@@upp.fares.details.contentOptionsExcludeNDCLabel" class="form-check-label"
                for="contentOptionsNDC"> NDC </label>
            </div>
          </div>
        </ng-template>
        <ng-template #priceRangeTemplate>
          <div role="radiogroup" aria-labelledby="radio-group-1" aria-labelledby="farePriceRange">
            <span class="label-font" id="farePriceRange">
              <ng-container i18n="@@upp.fares.details.PriceDifferenceLabel">Price Range</ng-container> <em
                class="icon-info-circle ms-3 me-3" [ngbTooltip]="priceRangeTooltip"></em>
            </span>
            <ng-template #priceRangeTooltip>
              <div class="d-flex flex-column">
                <span i18n="@@upp.fares.details.priceRange.tooltip.title">The range of results to exclude (as an amount or a percentage) compared to the fare price. For example: </span>
                <br />
                <span i18n="@@upp.fares.details.priceRange.tooltip.farePrice">Fare price: 100</span>
                <span i18n="@@upp.fares.details.priceRange.tooltip.lower">Lower limit: -30</span>
                <span i18n="@@upp.fares.details.priceRange.tooltip.upper">Upper limit: +30</span>
                <br />
                <span i18n="@@upp.fares.details.priceRange.tooltip.result">
                  Results will exclude fares with prices between 70 and 130.
                </span>
              </div>
            </ng-template>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="priceRangeTypeRate"
                value="R"
                [name]="elements.PRICE_RANGE_TYPE"
                [formControlName]="elements.PRICE_RANGE_TYPE" />
              <label i18n="@@upp.fares.details.priceRangeTypeRateLabel" class="form-check-label"
                for="priceRangeTypeRate">Percentage</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="priceRangeTypeFlat"
                value="F"
                [name]="elements.PRICE_RANGE_TYPE"
                [formControlName]="elements.PRICE_RANGE_TYPE" />
              <label i18n="@@upp.fares.details.priceRangeTypeFlatLabel" class="form-check-label"
                for="priceRangeTypeFlat">Amount</label>
            </div>
          </div>
          <div class="mt-3">
            <label i18n="@@upp.fares.details.priceDifferenceMinLabel">Lower limit</label>
            <input
              type="number"
              class="form-control"
              id="PriceDifferenceMin"
              [placeholder]="priceLowerLimitPlaceholder()"
              [formControlName]="elements.PRICE_DIFFERENCE_MIN"
              [ngbTooltip]="priceLowerLimitTooltip()"
              name="elements.PRICE_DIFFERENCE_MIN"
              [ngClass]="{ 'is-invalid': !lowerPriceValid() }" />
            <div *ngIf="!lowerPriceValid()" class="invalid mt-1"> {{ getLowerPriceRangeErrorMessage() }} </div>
          </div>
          <div class="mt-3">
            <label i18n="@@upp.fares.details.priceDifferenceMaxLabel">Upper limit</label>
            <input
              type="number"
              class="form-control"
              id="PriceDifferenceMax"
              [placeholder]=" priceUpperLimitPlaceholder()"
              [formControlName]="elements.PRICE_DIFFERENCE_MAX"
              [ngbTooltip]="priceUpperLimitTooltip()"
              name="elements.PRICE_DIFFERENCE_MAX"
              [ngClass]="{ 'is-invalid': !upperPriceValid() }"
              min="0" />
            <div *ngIf="!upperPriceValid()" class="invalid mt-1"> {{ getUpperPriceRangeErrorMessage() }} </div>
          </div>
        </ng-template>
        <ng-template #departureTimeRangeTemplate>
          <div class="row align-items-center mt-3">
            <div class="col-auto">
              <span class="label-font">
                <ng-container i18n="@@upp.fares.details.departureTimeRange.label">Departure Time Range</ng-container>
                <em class="icon-info-circle ms-3 me-3" [ngbTooltip]="departureTimeRangeTooltip"></em>
              </span>
              <ng-template #departureTimeRangeTooltip>
                <div class="d-flex flex-column">
                  <span i18n="@@upp.fares.details.departureTimeRange.tooltip.title"> The time range to exclude (in
                    minutes) compared to the departure time. For example: </span>
                  <br />
                  <span i18n="@@upp.fares.details.departureTimeRange.tooltip.departureTime">Departure time: 10:00</span>
                  <span i18n="@@upp.fares.details.departureTimeRange.tooltip.earliest">Earliest time: -30</span>
                  <span i18n="@@upp.fares.details.departureTimeRange.tooltip.latest">Latest time: +30</span>
                  <br />
                  <span i18n="@@upp.fares.details.departureTimeRange.tooltip.result"> Results will exclude fares with
                    departure times between 09:30 and 10:30. Positive and negative values are allowed. </span>
                </div>
              </ng-template>
            </div>
            <div class="col">
              <span class="form-sm">
                <label class="form-toggle-label form-toggle-name" for="enableDepartureToggle">
                  <div class="form-check form-switch">
                    <label class="form-check-label df-form-switch-name" for="enableDepartureToggle">
                      <div class="df-form-switch-container">
                        <input
                        class="form-check-input df-toggle-primary"
                        name="enableDepartureToggle"
                        type="checkbox"
                        (change)="onChangeDepartureToggle($event)"
                        formControlName="departureRangeActive"
                        id="enableDepartureToggle" />
                        <span class="form-check-label">{{ departureRangeActive() ? 'ON' : 'OFF' }}</span>
                      </div>
                    </label>
                  </div>
                </label>
              </span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <label for="departureTimeWindowStartLabel">Lower limit</label>
              <input
                [formControlName]="elements.DEPATURE_TIME_WINDOW_MIN" type="number"
                [readonly]="!departureRangeActive()"
                [value]="minDepatureTimeValue"
                class="form-control"
                placeholder="Lower departure time"
                i18n-placeholder="@@upp.fares.details.departureTimeRange.lowerLimit.placeholder"
                ngbTooltip="Enter a numeric value for the lower departure time"
                i18n-ngbTooltip="@@upp.fares.details.departureTimeRange.lowerLimit.tooltip"
                (keyup)="onChangeDepatureTimeMinEvent($event)"
                [ngClass]="{ 'is-invalid': !lowerDepartureTimeValid() }">
                <div *ngIf="!lowerDepartureTimeValid()" class="invalid mt-1">{{getLowerDepartureTimeErrorMessage()}}</div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <label for="departureTimeWindowEndLabel">Upper limit</label>
              <input
                [formControlName]="elements.DEPATURE_TIME_WINDOW_MAX" type="number"
                [readonly]="!departureRangeActive()"
                [value]="maxDepatureTimeValue"
                class="form-control"
                placeholder="Upper departure time"
                i18n-placeholder="@@upp.fares.details.departureTimeRange.upperLimit.placeholder"
                ngbTooltip="Enter a numeric value for the upper departure time"
                i18n-ngbTooltip="@@upp.fares.details.departureTimeRange.upperLimit.tooltip"
                (keyup)="onChangeDepatureTimeMaxEvent($event)"
                [ngClass]="{ 'is-invalid': !upperDepartureTimeValid() }">
                <div *ngIf="!upperDepartureTimeValid()" class="invalid mt-1">{{getUpperDepartureTimeErrorMessage()}}</div>
            </div>
          </div>
          <div class="row gx-3 align-items-center mt-3">
            <div class="col-auto pt-5">
              <span style="font-size: 1.1rem" class="icon-clock"></span>
            </div>
            <div class="col">
              <ngx-slider
                #s
                formControlName="departureTimeSlider"
                (userChange)="onSliderUserChange($event)"
                [options]="departureRangeActive() ? sliderOptions : readonlySlider"
                [dfSliderDirection]="s">
              </ngx-slider>
            </div>
            <div class="col-auto pt-5">
              <span style="font-size: 1.1rem" class="icon-clock"></span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</form>
