import { Component, inject } from '@angular/core';
import { Activatable } from '../../../model/activatable';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../../../service/configuration/configuration.service';
import { FeatureFlags } from '../../../core/util/resources';

@Component({
  selector: 'app-air-families-search',
  standalone: true,
  templateUrl: './air-families-search.component.html'
})
export class AirFamiliesSearchComponent extends Activatable {

  isActivated(): Observable<boolean> {
    return inject(ConfigurationService).getParameter$(FeatureFlags.familiesV2)
  }
}
