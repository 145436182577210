<nav class="navbar-expand-lg navbar-light d-inline-block h-100">
  <div
    *ngIf="!isNavigationDisabled()"
    id="sideNav"
    class="navbar navbar-light collapse navbar-collapse align-items-start h-100"
    [ngbCollapse]="isMenuCollapsed()"
  >
    <div class="navbar h-auto d-inline-block w-100">
      <ul class="navbar-nav flex-column">
        <li class="nav-item">
          <a
            id="dashboard-item"
            routerLink="/dashboard"
            routerLinkActive="is-active"
            class="nav-link"
            (click)="hideSideNav()"
            ><em class="icon-tachometer"></em> Dashboard</a
          >
        </li>
        <li class="nav-item" *ngIf="navigationService.posViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.posViewEnabled ? true : null"
            (click)="togglePos()"
            [routerLink]="isPosCollapsed() ? ['/pos/search'] : []"
          >
            <em class="icon-cash-register"></em> Points Of Sale&nbsp;
            <span
              [ngClass]="{ 'icon-angle-down': isPosCollapsed() === true, 'icon-angle-up': isPosCollapsed() === false }"
            >
            </span>
          </a>
          <div [ngbCollapse]="isPosCollapsed()" id="posSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/pos/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="icon-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/pos/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.posManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="icon-file-signature"></em> Create</a
                >
              </li>
              <li class="nav-link">
                <a
                  *ngIf="!posModifyViewEnabled"
                  routerLink="/pos/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!posDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-alt"></em> Display
                </a>
                <a
                  *ngIf="posModifyViewEnabled"
                  routerLink="/pos/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!posDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" *ngIf="navigationService.marketsViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.marketsViewEnabled ? true : null"
            (click)="toggleMarkets()"
            [routerLink]="isMarketsCollapsed() ? ['/markets/search'] : []"
          >
            <em class="icon-store"></em> Markets&nbsp;
            <span
              [ngClass]="{
                'icon-angle-down': isMarketsCollapsed() === true,
                'icon-angle-up': isMarketsCollapsed() === false
              }"
            >
            </span>
          </a>
          <div [ngbCollapse]="isMarketsCollapsed()" id="marketsSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/markets/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="icon-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/markets/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.marketsManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="icon-file-signature"></em> Create</a
                >
              </li>
              <li class="nav-link">
                <a
                  *ngIf="!marketsModifyViewEnabled"
                  routerLink="/markets/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!marketsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-alt"></em> Display
                </a>
                <a
                  *ngIf="marketsModifyViewEnabled"
                  routerLink="/markets/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!marketsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" *ngIf="navigationService.familiesViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.familiesViewEnabled ? true : null"
            (click)="toggleFamilies()"
            [routerLink]="isFamiliesCollapsed() ? ['/families/search'] : []"
          >
            <em class="icon-desktop"></em> Families&nbsp;
            <span
              [ngClass]="{
                'icon-angle-down': isFamiliesCollapsed() === true,
                'icon-angle-up': isFamiliesCollapsed() === false
              }"
              class="float-end"
            >
            </span>
          </a>
          <div [ngbCollapse]="isFamiliesCollapsed()" id="familiesSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/families/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="icon-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/families/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.familiesManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-signature"></em> Create</a
                >
              </li>

              <li class="nav-link">
                <a
                  *ngIf="!familiesModifyViewEnabled"
                  routerLink="/families/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!familiesDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-alt"></em> Display
                </a>
                <a
                  *ngIf="familiesModifyViewEnabled"
                  routerLink="/families/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!familiesDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>


        <li class="nav-item" *ngIf="navigationService.faresViewEnabled && dynamicExclusionV2 !== null">
          <a class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.faresViewEnabled ? true : null"
            (click)="toggleFares()"
            [routerLink]="isFaresCollapsed() ? ['/fares/search'] : []"
            [attr.data-test]="dynamicExclusionV2 ? 'dynamic-exclusions-link' : 'fares-link'"
          >
            <em class="icon-money-check-alt"></em>
            @if (dynamicExclusionV2) {
              <ng-container i18n="@@upp.navigation.dynamicExclusions.name">Dynamic Exclusions</ng-container>&nbsp;
            }
            @else {
              <ng-container i18n="@@upp.navigation.fares.name">Fares</ng-container>&nbsp;
            }
            <span
              [ngClass]="{
                'icon-angle-down': isFaresCollapsed() === true,
                'icon-angle-up': isFaresCollapsed() === false
              }"
              class="float-right"
            >
            </span>
          </a>
          <div [ngbCollapse]="isFaresCollapsed()" id="faresSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a
                  routerLink="/fares/search"
                  routerLinkActive="is-active"
                  class="nav-link"
                  (click)="hideSideNav()"
                  [attr.data-test]="dynamicExclusionV2 ? 'dynamic-exclusions-search-link' : 'fares-search-link'"
                  ><em class="icon-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/fares/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.faresManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  [attr.data-test]="dynamicExclusionV2 ? 'dynamic-exclusions-create-link' : 'fares-create-link'"
                  ><em class="icon-file-signature"></em> Create</a
                >
              </li>

              <li class="nav-link">
                <a
                  *ngIf="!faresModifyViewEnabled"
                  routerLink="/fares/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!faresDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-alt"></em> Display
                </a>
                <a
                  *ngIf="faresModifyViewEnabled"
                  routerLink="/fares/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!faresDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>


        <li class="nav-item" *ngIf="navigationService.flightsViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.flightsViewEnabled ? true : null"
            (click)="toggleFlights()"
            [routerLink]="isFlightsCollapsed() ? ['/flights/search'] : []"
          >
            <em class="icon-plane"></em> Flights&nbsp;
            <span
              [ngClass]="{
                'icon-angle-down': isFlightsCollapsed() === true,
                'icon-angle-up': isFlightsCollapsed() === false
              }"
              class="float-end"
            >
            </span>
          </a>
          <div [ngbCollapse]="isFlightsCollapsed()" id="flightsSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/flights/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="icon-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/flights/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.flightsManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="icon-file-signature"></em> Create</a
                >
              </li>

              <li class="nav-link">
                <a
                  *ngIf="!flightsModifyViewEnabled"
                  routerLink="/flights/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!flightsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-alt"></em> Display
                </a>
                <a
                  *ngIf="flightsModifyViewEnabled"
                  routerLink="/flights/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!flightsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>

        <li class="nav-item" *ngIf="navigationService.hotelsViewEnabled">
          <a
            class="df-collapse nav-link w-100"
            [class.disabled]="!navigationService.hotelsViewEnabled ? true : null"
            (click)="toggleHotels()"
            [routerLink]="isHotelsCollapsed() ? ['/hotels/search'] : []"
          >
            <em class="icon-hotel"></em> Hotels&nbsp;
            <span
              [ngClass]="{
                'icon-angle-down': isHotelsCollapsed() === true,
                'icon-angle-up': isHotelsCollapsed() === false
              }"
              class="float-end"
            >
            </span>
          </a>
          <div [ngbCollapse]="isHotelsCollapsed()" id="hotelsSubMenu" class="subMenu">
            <ul class="navbar-nav flex-column">
              <li class="nav-link">
                <a routerLink="/hotels/search" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
                  ><em class="icon-search"></em> Search</a
                >
              </li>
              <li class="nav-link">
                <a
                  routerLink="/hotels/create"
                  routerLinkActive="is-active"
                  [class.disabled]="!navigationService.hotelsManageEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                >
                  <em class="icon-file-signature"></em> Create</a
                >
              </li>
              <li class="nav-link">
                <a
                  *ngIf="!hotelsModifyViewEnabled"
                  routerLink="/hotels/display"
                  routerLinkActive="is-active"
                  [class.disabled]="!hotelsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-alt"></em> Display
                </a>
                <a
                  *ngIf="hotelsModifyViewEnabled"
                  routerLink="/hotels/modify"
                  routerLinkActive="is-active"
                  [class.disabled]="!hotelsDisplayViewEnabled ? true : null"
                  class="nav-link"
                  (click)="hideSideNav()"
                  ><em class="icon-file-edit"></em> Display
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li class="nav-item">
          <a routerLink="/about" routerLinkActive="is-active" class="nav-link" (click)="hideSideNav()"
            ><em class="icon-info-circle"></em> About</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
