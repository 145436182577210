import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UppComponent } from '../../service/model';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UserDetailsService } from '../../service/user-details.service';

import { JourneyUi, FlightRuleCreateRequest, convertToJourney, initialJourneyUi, journeyUiEquals } from '../model';
import {
  SetCreateFlightRuleValueAction,
  CreateFlightRuleAction,
  DeleteFlightRuleCreateNotificationAction,
  ActivateCreatedFlightRuleDisplayAction
} from '../../store/flight/flights-action';

import {
  selectFlightRuleCreateValue,
  selectFlightRuleCreateNotification,
  selectFlightRuleCreateSending
} from '../../store/flight/flights-selector';
import { FormComponent } from '../../model/FormComponent';

@Component({
  selector: 'ama-ng-upp-flight-create',
  templateUrl: './flights-create.component.html'
})
export class FlightsCreateComponent implements OnInit, FormComponent {
  notification$: Observable<UppNotification>;
  mainMessages: NotificationMessages;

  flightForm: UntypedFormGroup;
  journeyUi: JourneyUi;
  readonly = false;
  sendingStatus: boolean;

  uppComponent = UppComponent.FLIGHTS_CREATE;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly navigationService: NavigationService,
    private readonly userDetailsService: UserDetailsService,
    private readonly router: Router,
    private readonly store: Store<any>
  ) {
    this.notification$ = this.store.pipe(select(selectFlightRuleCreateNotification));
    this.store.pipe(select(selectFlightRuleCreateSending)).subscribe(sending => (this.sendingStatus = sending));
    this.mainMessages = {
      error: $localize`:@@upp.flights.create.mainErrorText:Creation of flight rule failed due to the following errors:`,
      warning: $localize`:@@upp.flights.create.mainWarningText:Warnings were generated during the creation process of flight rule:`,
      success: $localize`:@@upp.flights.create.mainSuccessText:Well done! You get this message about your successful Flight Rule creation`,
      linkSuccessText: $localize`:@@upp.flights.create.linkSuccessText:Display new Flight Rule: `
    };
  }

  ngOnInit(): void {
    this.navigationService.setSelectedMenuTitle($localize`:@@upp.flights.create.navigationTitle:Flight Rule Creation`);
    this.createAndLoadForm();
  }

  createAndLoadForm() {
    this.flightForm = this.formBuilder.group({});
    this.store.pipe(select(selectFlightRuleCreateValue)).subscribe(journey => {
      if (journey) {
        this.journeyUi = journey;
        this.flightForm.reset();
      }
    });
  }

  saveData() {
    // Touch all fields in order to display errors
    this.flightForm.markAllAsTouched();
    if (this.flightForm.valid) {
      const request = this.createRequest();
      request.journey.rule = this.userDetailsService.assignEntity(request.journey.rule);
      this.storeUnsavedChanges();
      this.store.dispatch(new CreateFlightRuleAction({ request }));
    }
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(new SetCreateFlightRuleValueAction({ value: this.flightForm.getRawValue() }));
  }

  createRequest(): FlightRuleCreateRequest {
    return {
      version: '1.0',
      journey: convertToJourney(this.flightForm.getRawValue())
    };
  }

  closeNotification() {
    this.store.dispatch(new DeleteFlightRuleCreateNotificationAction({}));
  }

  showFlightRuleInDisplay() {
    this.store.dispatch(new ActivateCreatedFlightRuleDisplayAction({}));
    this.router.navigate(['flights/display']);
  }

  hasUnsavedChanges(): boolean {
    return !journeyUiEquals(initialJourneyUi, this.flightForm.value);
  }

}
