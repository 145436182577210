<div [formGroup]="journeyFormGroup" class="d-inline-block w-100 mb-5">
  <label for="flightCategories" class="form-label">
    <ng-container i18n="@@upp.flights.details.journeyTypeLabel">Journey type</ng-container>
    <span class="mandatory ms-1">*</span>
  </label>
  <ama-ng-upp-lookup-select id="flightCategories" formControlName="flightCategories"
    groupBy="parent" [groupValue]="journeyTypesGroupValueFn" selectableGroup="true"
    [items]="journeyTypesOptions" bindValue="value" bindLabel="label" multiple="true"
    notFoundText="No journey type found" i18n-notFoundText="@@upp.flights.details.journeyTypeNotFound">
  </ama-ng-upp-lookup-select>
  <ama-ng-upp-validation-error-message [control]="journeyFormGroup.get('flightCategories')">
  </ama-ng-upp-validation-error-message>
</div>
