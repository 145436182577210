import { DestroyRef } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { getFormControlByPath, getTopParent } from '../../util/form-utils';
import { distinctUntilChanged, Subscription } from 'rxjs';

export const moreThanValidator = (
  relatedControl: AbstractControl | string,
  destroyRef: DestroyRef,
  inclusive = false
): ValidatorFn => {
  let initialized = false;
  let leftControl: AbstractControl | null = null;
  return (control: AbstractControl) => {
    if (!initialized && control.parent) {
      if (typeof relatedControl === 'string' || relatedControl instanceof String) {
        leftControl = getFormControlByPath(getTopParent(control), relatedControl as string);
      } else {
        leftControl = control;
      }
      // Force validation of this control when related control's value changes
      let subscription: Subscription | null = leftControl?.valueChanges.pipe(distinctUntilChanged((a, b) => a === b)).subscribe(() => {
        control.updateValueAndValidity();
      });
        destroyRef.onDestroy(() => {
          subscription?.unsubscribe();
          subscription = null;
        });
        initialized = true;
    }

    const referenceValue = +leftControl?.value;
    const value = control.value;
    if (referenceValue && value) {
      const isMoreThan = inclusive ? value >= referenceValue : value > referenceValue;
      if (!isMoreThan) {
        return {
          moreThan: {
            value,
            referenceValue
          }
        };
      }
    }

    return null;
  };
};
