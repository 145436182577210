<h1 i18n="@@upp.hotels.search.title">Search Hotel Rules</h1>

<div class="container-fluid">
  <ama-loading-alert
    [sendingStatus]="hotelRuleDeleteSending$ | async"
    message="Loading..."
    i18n-message="@@upp.global.messages.loading"
  >
  </ama-loading-alert>
</div>

<ama-upp-notification
  id="markets-search-notification"
  class="col"
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<ama-ng-upp-hotels-search-criteria
  (searchHotels)="searchHotelRules($event)"
  [sendingStatus]="hotelRuleSearchSending$ | async"
>
</ama-ng-upp-hotels-search-criteria>

<ama-ng-upp-basic-search-result-table
  [searchResults]="hotelRules$ | async"
  [sendingStatus]="hotelRuleSearchSending$ | async"
  [sourceComponent]="getSourceComponent()"
  [entryType]="getEntryType()"
  [recordToSearchResult]="recordToSearchResult"
  [entryToUpdateCreator]="entryToUpdateCreator"
  [entryToUpdateNameResolver]="entryToUpdateNameResolver"
  (deleteEntry)="deleteHotelRule($event)"
  (clearTableEvent)="clearHotelRules()"
  (showDetail)="showHotelRule($event)"
  (modifyDetail)="modifyHotelRule($event)"
  [managePermission]="managePermission"
  [copyForbidden]="true"
  [displayableFields]="displayableFields"
>
