<div class="container-fluid">
  <ama-loading-alert [sendingStatus]="sendingStatus" message="Loading..." i18n-message="@@upp.global.messages.loading">
  </ama-loading-alert>
</div>

<div class="criteria-container mx-auto mb-2">
  <form [formGroup]="searchCriteriaForm" id="marketsSearchCriteriaForm" (ngSubmit)="searchClicked()">
    <div class="row">
      <div *ngIf="!sendingStatus" class="col text-end mb-2">
        <button
          i18n="@@upp.global.button.clear"
          id="clear"
          type="button"
          class="btn btn-outline-primary btn-sm"
          (click)="clearClicked()"
        >
          Clear
        </button>
      </div>
      <div *ngIf="sendingStatus" class="col text-end mb-2">
        <button
          i18n="@@upp.global.button.clear"
          id="clearDisabled"
          type="button"
          disabled
          class="btn btn-outline-primary btn-sm"
          (click)="clearClicked()"
        >
          Clear
        </button>
      </div>
    </div>
    <div class="mb-5">
      <label i18n="@@upp.markets.search.nameLabel" for="name" class="form-label">Name </label>
      <input
        type="text"
        class="form-control"
        id="name"
        formControlName="name"
        [ngClass]="{ 'is-invalid': !searchCriteriaForm.controls['name'].valid }"
        placeholder="Market name"
        i18n-placeholder="@@upp.markets.display.namePlaceholder"
        ngbTooltip="Enter a market name, max. 30 alpha numeric"
        i18n-ngbTooltip="@@upp.markets.display.nameTooltip"
        oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
      />
      <div *ngIf="!searchCriteriaForm.controls['name'].valid" class="invalid mt-1">
        <div *ngIf="searchCriteriaForm.controls['name'].errors.maxlength">
          <ng-container i18n="@@upp.validation.markets.search.maximumCharacters"
            >Maximum number of characters acceptable:</ng-container
          >
          {{ searchCriteriaForm.controls['name'].errors.maxlength?.requiredLength }}
        </div>
        <div
          i18n="@@upp.validation.markets.search.typeOfCharacters"
          *ngIf="
            searchCriteriaForm.controls['name'].errors.pattern && !searchCriteriaForm.controls['name'].errors.maxlength
          "
        >
          Only alphanumeric characters are acceptable
        </div>
      </div>
    </div>
    <div class="mb-2">
      <div formGroupName="include">
        <div class="row mb-3 mt-1">
          <div class="col">
            <div class="d-flex flex-wrap align-items-center">
              <div class="form-check ms-1">
                <input class="form-check-input" type="checkbox" value="" id="worldCb" formControlName="world" />
                <label i18n="@@upp.global.labels.world" class="form-check-label" for="worldCb">World </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col col-md-3">
            <ama-ng-upp-multi-select-badges
              formElementName="regions"
              i18n-formElementName="@@upp.global.criteria.region.id"
              formElementLabel="Regions"
              i18n-formElementLabel="@@upp.global.criteria.region.label"
              [formGroup]="searchCriteriaForm.get('include')"
              placeholder="Region code"
              i18n-placeholder="@@upp.global.criteria.region.placeholder"
              [numberOfBadges]="numberOfItems"
              [customPasteRegExp]="regionPasteRegExp"
              [readonlyMode]="readonly"
              [badgeItems]="getRegionCodes()"
              [patchValue]="[]"
              notFoundText="No regions found"
              i18n-notFoundText="@@upp.global.criteria.region.notFoundText"
              tooltipText="Select or enter region code(s)"
              i18n-tooltipText="@@upp.global.criteria.region.tooltip"
              [parent]="COMPONENT_NAME"
            >
            </ama-ng-upp-multi-select-badges>
          </div>
          <div class="col col-md-3">
            <ama-ng-upp-multi-select-badges
              formElementName="countries"
              i18n-formElementName="@@upp.global.criteria.country.id"
              formElementLabel="Countries"
              i18n-formElementLabel="@@upp.global.criteria.country.label"
              [formGroup]="searchCriteriaForm.get('include')"
              placeholder="Country code"
              i18n-placeholder="@@upp.global.criteria.country.placeholder"
              [numberOfBadges]="numberOfItems"
              [readonlyMode]="readonly"
              [badgeItems]="getCountryCodes()"
              [patchValue]="[]"
              notFoundText="No countries found"
              i18n-notFoundText="@@upp.global.criteria.country.notFoundText"
              tooltipText="Select or enter 2 letter IATA country code(s)"
              i18n-tooltipText="@@upp.global.criteria.country.tooltip"
              [parent]="COMPONENT_NAME"
            >
            </ama-ng-upp-multi-select-badges>
          </div>
          <div class="col col-md-3">
            <ama-ng-upp-multi-select-badges
              formElementName="cities"
              i18n-formElementName="@@upp.global.criteria.city.id"
              formElementLabel="Cities"
              i18n-formElementLabel="@@upp.global.criteria.city.label"
              [formGroup]="searchCriteriaForm.get('include')"
              placeholder="IATA city code"
              i18n-placeholder="@@upp.global.criteria.city.placeholder"
              [numberOfBadges]="numberOfItems"
              [readonlyMode]="readonly"
              [patchValue]="[]"
              notFoundText="No cities found"
              i18n-notFoundText="@@upp.global.criteria.city.notFoundText"
              tooltipText="Enter 3 letter IATA city code(s)"
              i18n-tooltipText="@@upp.global.criteria.city.tooltip"
              [parent]="COMPONENT_NAME"
            >
            </ama-ng-upp-multi-select-badges>
          </div>
          <div class="col col-md-3">
            <ama-ng-upp-multi-select-badges
              formElementName="airports"
              i18n-formElementName="@@upp.global.criteria.airport.id"
              formElementLabel="Airports"
              i18n-formElementLabel="@@upp.global.criteria.airport.label"
              [formGroup]="searchCriteriaForm.get('include')"
              placeholder="IATA airport code"
              i18n-placeholder="@@upp.global.criteria.airport.placeholder"
              [numberOfBadges]="numberOfItems"
              [readonlyMode]="readonly"
              [patchValue]="[]"
              notFoundText="No airports found"
              i18n-notFoundText="@@upp.global.criteria.airport.notFoundText"
              tooltipText="Enter 3 letter IATA airport code(s)"
              i18n-tooltipText="@@upp.global.criteria.airport.tooltip"
              [parent]="COMPONENT_NAME"
            >
            </ama-ng-upp-multi-select-badges>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <button
        i18n="@@upp.global.button.search"
        *ngIf="!sendingStatus"
        id="search"
        type="submit"
        class="btn btn-primary mb-2 mb-md-0 me-2"
        [disabled]="!searchCriteriaForm.valid"
      >
        Search
      </button>

      <button
        i18n="@@upp.global.button.search"
        *ngIf="sendingStatus"
        id="searchIsSending"
        type="submit"
        disabled
        class="btn btn-primary mb-2 mb-md-0 me-2"
      >
        Search
      </button>
    </div>
  </form>
</div>
