<h1 i18n="@@upp.flights.search.title">Search Flight Rules</h1>

<div class="container-fluid">
  <ama-loading-alert
    [sendingStatus]="flightRuleDeleteSending$ | async"
    message="Loading..."
    i18n-message="@@upp.global.messages.loading"
  >
  </ama-loading-alert>
</div>

<ama-upp-notification
  id="markets-search-notification"
  class="col"
  [mainMessages]="mainMessages"
  [notification]="notification$ | async"
  (closeNotificationEvent)="closeNotification()"
>
</ama-upp-notification>

<app-flight-search-criteria
  (searchFlights)="searchFlightRules($event)"
  [sendingStatus]="flightRuleSearchSending$ | async">
</app-flight-search-criteria>

<ama-ng-upp-basic-search-result-table
  [searchResults]="flightRules$ | async"
  [sendingStatus]="flightRuleSearchSending$ | async"
  [sourceComponent]="getSourceComponent()"
  [entryType]="getEntryType()"
  idPath="journey.rule.id"
  [recordToSearchResult]="recordToSearchResult.bind(this)"
  [entryToUpdateCreator]="entryToUpdateCreator"
  [entryToUpdateNameResolver]="entryToUpdateNameResolver"
  (deleteEntry)="deleteFlightRule($event)"
  (clearTableEvent)="clearFlightRules()"
  (showDetail)="showFlightRule($event)"
  (modifyDetail)="modifyFlightRule($event)"
  (copyDetail)="copyFlightRule($event)"
  [managePermission]="getFlightSearchRecordPermissions.bind(this)"
  [displayableFields]="displayableFields"
  data-test="flight-results-table"
>
</ama-ng-upp-basic-search-result-table>
