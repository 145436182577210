import { AbstractControl, FormArray, ValidationErrors } from '@angular/forms';
import { JourneyContentAction } from '../../model/journey';

/**
 * This validator should be used once on the parent group of the controls it should validate.
 * It manages the error state of specific controls in the form group.
 */
export const excludeSubruleShouldBePresent = (control: AbstractControl): ValidationErrors | null => {

  const subruleControls = (control as FormArray).controls;
  const subruleActionOrValueControls = subruleControls.map(x => x.get('content'));

  const forceIncludeActionOrValueControls = subruleActionOrValueControls.filter(x => x.value?.action === JourneyContentAction.ForceInclude);
  const excludeActionOrValueControlsPresent = subruleActionOrValueControls.some(x => x.value?.action === JourneyContentAction.Exclude);

  if (forceIncludeActionOrValueControls.length > 0 && !excludeActionOrValueControlsPresent) {
    forceIncludeActionOrValueControls.forEach(x => {
      x.setErrors({ ...x.errors, excludeSubruleRequired: {} });
    });
  } else {
    forceIncludeActionOrValueControls.forEach(x => {
      if (x.hasError('excludeSubruleRequired')) {
        delete x.errors['excludeSubruleRequired'];
        x.updateValueAndValidity();
      }
    });
  }

  return null;
};
