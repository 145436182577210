import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueLabelItem } from '../../model/value-label-item';

@Component({
  selector: 'ama-ng-upp-checkbox-multiselect',
  templateUrl: './checkbox-multiselect.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CheckboxMultiselectComponent
    }
  ]
})
export class CheckboxMultiselectComponent implements ControlValueAccessor {
  // User should pass ValueLabelItem[]. Selected flag is for internal use only.
  @Input() items: SelectableValueLabelItem[] = [];

  /**
   * Compare function similar to ng-select#compareWith.
   * If item values are not primitives and we want some comparison by value, then such function should be provided.
   * @param a first parameter
   * @param b second parameter
   * @returns boolean true if parameters are equal, false otherwise
   */
  @Input() compareWith = (a: any, b: any) => a === b;

  disabled = false;

  private onChange = (_value: any[]) => {};
  private onTouched = () => {};

  constructor() {}

  writeValue(newValue: any[]): void {
    const includesFn = (item: SelectableValueLabelItem) =>
      newValue?.some((value) => this.compareWith(value, item.value));
    this.items.forEach((item) => (item.selected = includesFn(item)));
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  toggleSelected(selectedItem: SelectableValueLabelItem): void {
    selectedItem.selected = !selectedItem.selected;

    const value = this.items.filter((item) => item.selected).map((item) => item.value);

    this.onChange(value);
    this.onTouched();
  }
}

interface SelectableValueLabelItem extends ValueLabelItem {
  selected?: boolean;
}
