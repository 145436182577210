import {
  Component, Input, OnInit, OnDestroy,
  OnChanges, SimpleChanges
} from '@angular/core';
import {
  FormArray, FormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import { JourneyUiRouteMarket, JourneyUiRouteScope } from '../../model';
import { Store, select } from '@ngrx/store';
import { LookupOptions } from 'src/app/core/util/lookup-options';
import { UppComponentNames, UppViewNames } from '../../../service/model/upp-component';
import { Subscription } from 'rxjs';
import { initialJourneyUiRouteMarket, initialJourneyUiRouteScope } from '../../model/flight-rule-ui';
import { selectAllAvailableMarketsRecords } from '../../../store/markets/markets-selector';

@Component({
  selector: 'ama-ng-upp-route-content',
  templateUrl: './route-content.component.html',
  styleUrls: ['./route-content.component.scss']
})
export class RouteContentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() routeFormGroup: UntypedFormGroup;
  @Input() routeScope: JourneyUiRouteScope = initialJourneyUiRouteScope;
  @Input() parentSubruleIndex: number;
  @Input() sourceView: UppViewNames;
  @Input() readonly = false;

  marketLookupOptions: LookupOptions = {
    destinationComponent: UppComponentNames.MARKETS,
    sourceComponent: UppComponentNames.FLIGHTS,
    sourceView: UppViewNames.CREATE
  };

  UppComponentNames = UppComponentNames;

  availableMarkets: string[];

  private readonly subscriptions: Subscription[] = [];

  marketPositionOptions = Array(13).fill(0)
    .map((_x, i) => (i - 6))
    .filter(x => x !== 0);

  get marketsFormArray(): FormArray {
    return this.routeFormGroup.get('markets') as FormArray;
  }

  constructor(
    private readonly store: Store,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe(result => {
        if (result != null) {
          this.availableMarkets = result.map(posMarketRecord => posMarketRecord.posMarketDetail.name);
        }
      })
    );

    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.routeScope) {
      this.initializeForm();
    }
  }

  ngOnDestroy(): void {
    this.routeFormGroup.reset({});

    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  private initializeForm(): void {
    this.routeFormGroup?.setControl('markets', this.formBuilder.array([]));

    if (this.routeFormGroup) {
      if (this.routeScope?.markets?.length > 0) {
        this.routeScope.markets.forEach(market => {
          this.addRouteContentSection(market);
        });
      } else {
        this.addRouteContentSection();
      }
    }
  }

  removeButtonClicked(index: number): void {
    this.removeRouteContentSection(index);
  }

  addButtonClicked(): void {
    this.addRouteContentSection();
  }

  private addRouteContentSection(existingMarket?: JourneyUiRouteMarket): void {
    const dataModel = existingMarket ? existingMarket : initialJourneyUiRouteMarket;

    const marketControl = this.formBuilder.group({
      name: [{ value: dataModel.name, disabled: this.readonly }, [Validators.required]],
      position: [{ value: dataModel.position, disabled: this.readonly }]
    });

    this.marketsFormArray.push(marketControl);
  }

  private removeRouteContentSection(index: number): void {
    if (this.marketsFormArray.length <= 1) {
      return;
    }

    this.marketsFormArray.removeAt(index);
  }
}
