import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { UppNotification } from '../../model/notification';
import { NavigationService } from '../../service/core/navigation.service';
import { UppComponent } from '../../service/model';
import { UserDetailsService } from '../../service/user-details.service';
import { PERMISSION_MANAGE_FARES } from '../../service/user-permissions.service';
import {
  ActivateFareRuleDisplayAction,
  DeleteFareRuleDisplayNotificationAction,
  SetFareModifyValueAction,
  StartFareModificationAction
} from '../../store/fares/fares-action';
import {
  selectFareRuleDisplayActivated,
  selectFareRuleDisplayNotification,
  selectFareRuleDisplayValue
} from '../../store/fares/fares-selector';
import { FareRuleUi, initialFareRuleUi } from '../model/fare-rule-ui';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';

@Component({
  selector: 'ama-ng-upp-fares-display',
  templateUrl: './fares-display.component.html',
  styleUrls: ['./fares-display.component.scss']
})
export class FaresDisplayComponent implements OnInit {
  fareForm: UntypedFormGroup;
  fareRule: FareRuleUi = initialFareRuleUi;
  notification$: Observable<UppNotification>;
  displayActivated: boolean;

  readonly = true;
  uppComponent = UppComponent.FARES_DISPLAY;
  mainMessages: NotificationMessages;

  showFaresV2: boolean;

  constructor(
    private readonly navigationService: NavigationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userDetailsService: UserDetailsService,
    private readonly configurationService: ConfigurationService
  ) {
    this.store
      .pipe(select(selectFareRuleDisplayActivated))
      .subscribe(activated => (this.displayActivated = activated));
    this.notification$ = this.store.pipe(select(selectFareRuleDisplayNotification));
    this.showFaresV2 = this.configurationService.getParameter<boolean>(FeatureFlags.dynamicExclusionV2);
    if (this.showFaresV2) {
      this.mainMessages = {
        error: $localize`:@@upp.dynamicExclusions.display.mainErrorText:The following errors for dynamic exclusion rule appeared:`,
        warning: $localize`:@@upp.dynamicExclusions.display.mainWarningText:The following warning for dynamic exclusion rule appeared:`,
        success: $localize`:@@upp.dynamicExclusions.display.mainSuccessText:The dynamic exclusion rule was stored successfully.`
      };
    } else {
      this.mainMessages = {
        error: $localize`:@@upp.fares.display.mainErrorText:The following errors for fare rule appeared:`,
        warning: $localize`:@@upp.fares.display.mainWarningText:The following warning for fare rule appeared:`,
        success: $localize`:@@upp.fares.display.mainSuccessText:The fare rule was stored successfully.`
      };
    }
  }

  ngOnInit() {
    this.createForm();
    if (this.showFaresV2) {
      this.navigationService.setSelectedMenuTitle(
        $localize`:@@upp.dynamicExclusions.display.navigationTitle:Dynamic Exclusion rule display`
      );
    } else {
      this.navigationService.setSelectedMenuTitle('Fare rule display');
    }
    this.navigationService.activateFares();
    if (!this.displayActivated) {
      this.router.navigate(['fares/search/']);
    } else {
      this.loadFareRule();
    }
  }

  createForm() {
    this.fareForm = this.formBuilder.group({});
    this.store.pipe(select(selectFareRuleDisplayValue)).subscribe(value => (this.fareRule = value));
  }

  loadFareRule() {
    this.route.paramMap.subscribe((params: ParamMap) => this.activateFareRuleWithId(params.get('id')));
  }

  activateFareRuleWithId(paramId: string) {
    this.store.dispatch(new ActivateFareRuleDisplayAction({ id: paramId }));
  }

  closeNotification() {
    this.store.dispatch(new DeleteFareRuleDisplayNotificationAction({}));
  }

  modifyButtonEnabled(): boolean {
    return this.hasManageAccess() && this.fareRule !== null && this.fareRule !== undefined;
    // TODO add mandatory fields checks
  }

  modifyFare() {
    this.store.dispatch(new StartFareModificationAction({ id: undefined }));
    this.store.dispatch(new SetFareModifyValueAction({ value: this.fareRule }));
    this.router.navigate(['fares/modify/'], { queryParams: { sourceView: 'display' } });
  }

  hasManageAccess() {
    const permissions = this.userDetailsService.loggedInUser.permissions;
    return permissions.filter(p => p === PERMISSION_MANAGE_FARES).length > 0;
  }
}
