import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { LoginModule, ClpService, ClpLoaderService } from '@seco/login';
import { HttpModule as SecoHttpModule, MockClpLoaderService } from '@seco/dev-utils';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { ReefServerModule, ReefRESTService, AnalyticsService, HttpConfigService } from '@seco/core';
import { CORE_FEATURE } from './store/core/state';
import { coreReducer } from './store/core/reducer';
import { POS_FEATURE } from './store/pos/pos-state';
import { posReducer } from './store/pos/pos-reducer';
import { MARKETS_FEATURE } from './store/markets/markets-state';
import { marketsReducer } from './store/markets/markets-reducer';
import { Effects } from './store/core/effects.service';
import { PosEffects } from './store/pos/pos-effects.service';
import { MarketsEffects } from './store/markets/markets-effects.service';
import { EffectsModule } from '@ngrx/effects';
import { LoginService } from './service/login.service';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './core/header/header.component';
import { NavigationMenuComponent } from './core/navigation-menu/navigation-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AboutComponent } from './core/about/about.component';
import { FooterComponent } from './core/footer/footer.component';
import { LoginComponent } from './core/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PointsOfSaleModule } from './points-of-sale/points-of-sale.module';
import { MarketsModule } from './markets/markets.module';
import { HotelsModule } from './hotels/hotels.module';
import { HOTELS_FEATURE } from './store/hotel/hotels-state';
import { hotelsReducer } from './store/hotel/hotels-reducer';
import { HotelsEffects } from './store/hotel/hotels-effects.service';
import { FlightsModule } from './flights/flights.module';
import { FLIGHTS_FEATURE } from './store/flight/flights-state';
import { flightsReducer } from './store/flight/flights-reducer';
import { FlightsEffects } from './store/flight/flights-effects.service';
import { FamiliesModule } from './families/families.module';
import { FAMILIES_FEATURE } from './store/families/families-state';
import { familiesReducer } from './store/families/families-reducer';
import { FamiliesEffects } from './store/families/families-effects.service';
import { CardComponent } from './card/card.component';
import { CardDeckComponent } from './card-deck/card-deck.component';
import { NAVIGATION_FEATURE } from './store/navigation/navigation-state';
import { navigationReducer } from './store/navigation/navigation-reducer';
import { PipesModule } from './core/util/pipes/pipes.module';
import { environment } from '../environments/environment';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DfAlertModule } from '@design-factory/design-factory';
import { FaresModule } from './fares/fares.module';
import { FARES_FEATURE } from './store/fares/fares-state';
import { faresReducer } from './store/fares/fares-reducer';
import { FaresEffects } from './store/fares/fares-effects.service';
import { DummyAnalyticsService } from './service/dummy-analytics.service';
import { Observable, of } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { URLUtils } from './util/url-utils';

export function initializeApp(
  loginService: LoginService,
  httpConfigService: HttpConfigService
): () => Observable<any> {
  return () => {
    const siteCode = '0SLW0SLW';
    const language = 'GB';
    const baseUrl = '/app_upp_ui';

    httpConfigService.initConfig({
      siteCode,
      language,
      baseUrl,
      environment: URLUtils.getQueryParamValue(window.location.href, 'OCTX')
    });

    // Trigger a timout after 10 secs
    return loginService
      .getClpConfig()
      .pipe(timeout(AppModule.MAX_INIT_TIME))
      .pipe(
        catchError((err) => {
          console.log('Error on getClpConfig:', err);
            // Avoid to block application
            return of();
          })
      );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationMenuComponent,
    DashboardComponent,
    AboutComponent,
    FooterComponent,
    LoginComponent,
    CardComponent,
    CardDeckComponent,
    ConfirmationModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    SecoHttpModule.forRoot(undefined, environment.useMockServer),
    StoreModule.forRoot({}),
    StoreModule.forFeature(CORE_FEATURE, coreReducer),
    StoreModule.forFeature(POS_FEATURE, posReducer),
    StoreModule.forFeature(MARKETS_FEATURE, marketsReducer),
    StoreModule.forFeature(HOTELS_FEATURE, hotelsReducer),
    StoreModule.forFeature(FLIGHTS_FEATURE, flightsReducer),
    StoreModule.forFeature(FAMILIES_FEATURE, familiesReducer),
    StoreModule.forFeature(FARES_FEATURE, faresReducer),
    EffectsModule.forFeature([
      PosEffects,
      MarketsEffects,
      HotelsEffects,
      FlightsEffects,
      FamiliesEffects,
      FaresEffects,
      Effects
    ]),
    StoreModule.forFeature(NAVIGATION_FEATURE, navigationReducer),
    EffectsModule.forRoot([]),
    LoginModule,
    ReactiveFormsModule,
    PointsOfSaleModule,
    MarketsModule,
    HotelsModule,
    FlightsModule,
    FamiliesModule,
    FaresModule,
    ReefServerModule.forRoot(),
    AppRoutingModule,
    PipesModule,
    DfAlertModule
  ],
  providers: [
    {provide: AnalyticsService, useClass: DummyAnalyticsService},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ LoginService, HttpConfigService ],
      multi: true
    },
    LoginService,
    ClpService,
    ReefRESTService,
    { provide: ClpLoaderService, useClass: environment.useMockServer ? MockClpLoaderService : ClpLoaderService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Max time to startup app : 10 secs
  public static readonly MAX_INIT_TIME = 10_000;
}
