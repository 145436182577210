import { Component, OnDestroy, OnInit } from '@angular/core';
import { UppComponent } from '../../service/model';
import { Observable, Subscription } from 'rxjs';
import { UppNotification } from '../../model/notification';
import { NotificationMessages } from '../../components/upp-notification/upp-notification.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NavigationService } from '../../service/core/navigation.service';
import { Store, select } from '@ngrx/store';
import {
  selectFlightModifySending,
  selectFlightModifyValue,
  selectFlightModifyNotification
} from '../../store/flight/flights-selector';
import {
  UpdateFlightAction,
  SetFlightModifyValueAction,
  CancelFlightModificationAction,
  DeleteFlightModifyNotificationAction
} from '../../store/flight/flights-action';
import { UserDetailsService } from '../../service/user-details.service';
import { convertToJourney, EXCLUDE_ACTION, JourneyUi, FlightRuleUpdateRequest, journeyUiEquals } from '../model';
import { FormComponent } from '../../model/FormComponent';
import { SOURCE_VIEW_DISPLAY, SOURCE_VIEW_QUERY_PARAM_KEY } from '../../../app/service/core/navigation-constants';
@Component({
  selector: 'ama-ng-upp-flights-modify',
  templateUrl: './flights-modify.component.html',
  styleUrls: ['./flights-modify.component.scss']
})
export class FlightsModifyComponent implements OnInit, OnDestroy, FormComponent {
  flightDetail: JourneyUi = {
    rule: {
      organization: '',
      name: '',
      active: true,
      action: EXCLUDE_ACTION
    },
    applicability: {
      pointOfSaleName: ''
    }
  };
  notification$: Observable<UppNotification>;

  mainMessages: NotificationMessages;
  sendingStatus: boolean;
  activated: boolean;

  flightForm: UntypedFormGroup;
  uppComponent = UppComponent.MARKETS_MODIFY;
  readonly = false;

  sourceView: string;

  private subscriptions: Subscription[] = [];
  private lastSavedJourneyUi: JourneyUi;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly navigationService: NavigationService,
    private readonly router: Router
  ) {
    this.subscriptions.push(this.store.pipe(select(selectFlightModifySending)).subscribe(sending => (this.sendingStatus = sending)));
    this.subscriptions.push(this.store.pipe(select(selectFlightModifyValue)).subscribe(
      value => (this.lastSavedJourneyUi = this.flightDetail = value)));
    this.notification$ = this.store.pipe(select(selectFlightModifyNotification));
    this.mainMessages = {
      error: $localize`:@@upp.flights.modify.mainErrorText:The following errors for flight rule appeared:`,
      warning: $localize`:@@upp.flights.modify.mainWarningText:The following warning for flight rule appeared:`,
      success: $localize`:@@upp.flights.modify.mainSuccessText:The flight rule was stored successfully.`
    };
  }

  ngOnInit() {
    this.navigationService.setSelectedMenuTitle('Flights Modification');
    this.navigationService.activateFlights();
    this.createAndLoadForm();
    this.route.queryParams.subscribe((params: Params) => {
      this.sourceView = params[SOURCE_VIEW_QUERY_PARAM_KEY];
    });
  }

  createAndLoadForm() {
    this.flightForm = this.formBuilder.group({});
    if (this.flightDetail) {
      this.flightForm.patchValue(this.flightDetail);
    }
  }

  cancelModification() {
    const id = this.flightDetail.rule.id;
    this.store.dispatch(new CancelFlightModificationAction({}));
    if (this.sourceView === SOURCE_VIEW_DISPLAY) {
      this.router.navigate(['flights/display/' + id]);
    } else {
      this.router.navigate(['/flights/search']);
    }
  }

  saveModification() {
    // Touch all fields in order to display errors
    this.flightForm.markAllAsTouched();
    if (this.flightForm.valid) {
      const request = this.createRequest();
      request.journey.rule = this.userDetailsService.assignEntity(request.journey.rule);
      this.store.dispatch(new UpdateFlightAction({ request }));
    }
  }

  storeUnsavedChanges(): void {
    this.store.dispatch(
      new SetFlightModifyValueAction({
        value: this.flightForm.getRawValue()
      })
    );
  }

  closeNotification() {
    this.store.dispatch(new DeleteFlightModifyNotificationAction({}));
  }

  hasUnsavedChanges(): boolean {
    return !journeyUiEquals(this.lastSavedJourneyUi, this.flightForm.value);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  private createRequest(): FlightRuleUpdateRequest {
    const journey = convertToJourney(this.flightForm.getRawValue());
    if (this.flightDetail.rule.id) {
      journey.rule.id = this.flightDetail.rule.id;
    }
    if (this.flightDetail.rule.version) {
      journey.rule.version = this.flightDetail.rule.version;
    }

    return {
      version: '1.0',
      journey
    };
  }
}
