<form [formGroup]="hotelDetailsForm" id="hotelDetailsForm">
  <div class="mb-3">
    <div class="row mt-3">
      <div class="col text-end">
        <button
          i18n="@@upp.global.button.clear"
          *ngIf="!readonly"
          type="button"
          class="btn btn-outline-primary btn-sm"
          (click)="clearScreen()"
        >
          Clear
        </button>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-2">
        <div class="form-check form-switch">
          <label class="form-check-label df-form-switch-name" for="activationToggle"
            >Activate Rule
            <div class="df-form-switch-container">
              <input
                class="form-check-input df-toggle-primary"
                name="activationToggle"
                type="checkbox"
                id="activationToggle"
                formControlName="active"
                [readonly]="readonly"
              />
              <span class="form-check-label">{{ isRuleActivated() ? 'On' : 'Off' }}</span>
            </div>
          </label>
        </div>
      </div>
      <div class="col-10"></div>
    </div>

    <div class="row mt-3">
      <div class="col mb-5">
        <label class="label-font" for="name" class="form-label"
          ><ng-container i18n="@@upp.hotels.details.nameLabel">Name</ng-container><span class="mandatory">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          [readonly]="this.readonly"
          [ngClass]="{ 'is-invalid': !hotelDetailsForm.controls['name'].valid }"
          placeholder="Hotel rule name"
          i18n-placeholder="@@upp.hotels.common.namePlaceholder"
          ngbTooltip="Enter a hotel rule name, max. 30 alpha numeric without spaces"
          i18n-ngbTooltip="@@upp.hotels.common.nameTooltip"
          oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
        />
        <div *ngIf="!hotelDetailsForm.controls['name'].valid" class="invalid mt-1">
          {{ getNameValidationError() }}
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col mb-5">
        <label i18n="@@upp.hotels.details.descriptionLabel" class="label-font" for="description">Description</label>
        <input
          type="text"
          class="form-control"
          id="description"
          formControlName="description"
          [ngClass]="{ 'is-invalid': !hotelDetailsForm.controls['description'].valid }"
          [readonly]="this.readonly"
          placeholder="Hotel rule description"
          i18n-placeholder="@@upp.hotels.details.descriptionPlaceholder"
          ngbTooltip="Enter a description for this hotel rule, max. 128 alpha numeric"
          i18n-ngbTooltip="@@upp.hotels.details.descriptionTooltip"
        />
        <div *ngIf="!hotelDetailsForm.controls['description'].valid" class="invalid mt-1">
          <ng-container i18n="@@upp.validation.hotels.numberOfCharacters"
            >Maximum/Minimum number of characters acceptable:</ng-container
          >
          {{ hotelDetailsForm.controls['description'].errors.maxlength.requiredLength }}
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-2">
    <div i18n="@@upp.hotels.create.textExcludeIncludeSelection" class="col">
      Please choose to exclude or include your criteria
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <div class="d-flex flex-wrap align-items-center">
        <div class="me-2">
          <button
            i18n="@@upp.global.labels.exclude"
            type="button"
            id="exclude"
            [disabled]="this.readonly"
            [ngClass]="
              isExcludeSelected()
                ? 'btn btn-exclude btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'
                : 'btn btn-outline-exclude btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'
            "
            (click)="setExcludeSelected()"
          >
            Exclude
          </button>
          <button
            i18n="@@upp.global.labels.include"
            type="button"
            id="include"
            [disabled]="this.readonly"
            [ngClass]="
              isIncludeSelected()
                ? 'btn btn-include btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'
                : 'btn btn-outline-include btn-criteria-lg btn-sm mt-2 mb-md-0 me-4'
            "
            (click)="setIncludeSelected()"
          >
            Include
          </button>
        </div>
        <div>
          <input type="hidden" formControlName="action" />
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col">
      <div class="d-flex flex-wrap align-items-center">
        <div *ngIf="isExcludeSelected()" class="form-check ms-3">
          <input class="form-check-input" type="checkbox" value="" id="lightExclude" formControlName="lightExclude" />
          <label i18n="@@upp.hotels.details.lightExcludeLabel" class="form-check-label move-right" for="lightExclude"
            >Light Exclude
          </label>
          <span
            class="icon-info-circle"
            placement="top auto"
            ngbTooltip="A light exclude will exclude a criterion as long as it is not explicitly searched. Example: Hotel chain is excluded and travel agent searches for a dedicated hotel property code belonging to this chain -> this property code is NOT excluded in availability display."
            i18n-ngbTooltip="@@upp.hotels.details.lightExcludeTooltip"
          >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div formGroupName="hotelApplicability">
    <div class="mb-2">
      <div class="row mb-2">
        <div class="col">
          {{ getCriteriaSelectionText() }}
        </div>
      </div>
      <ama-ng-upp-criteria-list
        [formGroup]="hotelDetailsForm.get('hotelApplicability')"
        [readonly]="readonly"
        [criteriaList]="getCriteriasForNames(criteriaGroupOne)"
        [largeButtons]="true"
        [parent]="COMPONENT_NAME"
      ></ama-ng-upp-criteria-list>
    </div>
    <div class="mb-2">
      <ama-ng-upp-criteria-list
        [formGroup]="hotelDetailsForm.get('hotelApplicability')"
        [readonly]="readonly"
        [criteriaList]="getCriteriasForNames(criteriaGroupTwo)"
        [parent]="COMPONENT_NAME"
        [largeButtons]="true">
      </ama-ng-upp-criteria-list>
    </div>
    <ama-ng-upp-criteria-list
      [formGroup]="hotelDetailsForm.get('hotelApplicability')"
      [readonly]="readonly"
      [criteriaList]="getCriteriasForNames(criteriaGroupThree)"
      [largeButtons]="true"
      [parent]="COMPONENT_NAME"
      (customCriteriaClicked)="toggleOtherCriteriaFields($event)">
      <ng-template #sourceTypesTemplate>
        <div class="d-flex flex-wrap">
          <span i18n="@@upp.hotels.details.sourceTypesLabel" class="label-criteria">Source Types</span>
          <div class="form-check ms-2">
            <input class="form-check-input" type="checkbox" id="gds" formControlName="sourceTypesGds" />
            <label i18n="@@upp.hotels.details.gds" class="form-check-label" for="gds">GDS</label>
          </div>
          <div class="form-check ms-2">
            <input class="form-check-input" type="checkbox" id="aggregator" formControlName="sourceTypesAggregator" />
            <label i18n="@@upp.hotels.details.aggregator" class="form-check-label" for="aggregator">Aggregator</label>
          </div>
        </div>
      </ng-template>
    </ama-ng-upp-criteria-list>
    <ama-ng-upp-criteria-list
      [formGroup]="hotelDetailsForm.get('hotelApplicability')"
      [readonly]="readonly"
      [criteriaList]="getCriteriasForNames(criteriaGroupFour)"
      [largeButtons]="true"
      [parent]="COMPONENT_NAME"
      (customCriteriaClicked)="toggleOtherCriteriaFields($event)">
      <ng-template #comissionableTemplate>
        <div role="radiogroup" aria-labelledby="commissionable">
          <div class="d-flex flex-wrap">
            <span i18n="@@upp.hotels.details.commissionableLabel" class="label-criteria" id="commissionable">Commissionable</span>
            <div class="form-check form-check-inline ms-2">
              <input
                class="form-check-input"
                type="radio"
                id="commissionableYes"
                value="yes"
                name="commissionable"
                formControlName="commissionable" />
              <label i18n="@@upp.global.labels.yes" class="form-check-label" for="commissionableYes"> Yes </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                id="commissionableNo"
                value="no"
                name="commissionable"
                formControlName="commissionable" />
              <label i18n="@@upp.global.labels.no" class="form-check-label" for="commissionableNo"> No </label>
            </div>
          </div>
        </div>
      </ng-template>
    </ama-ng-upp-criteria-list>
  </div>
</form>
