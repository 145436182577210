import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { UserDetailsService } from '../user-details.service';
import {
  PERMISSION_MANAGE_EDIFACT_RULE,
  PERMISSION_MANAGE_FAMILY,
  PERMISSION_MANAGE_FARES,
  PERMISSION_MANAGE_FLIGHT,
  PERMISSION_MANAGE_HOTEL,
  PERMISSION_MANAGE_MARKET,
  PERMISSION_MANAGE_NDC_RULE,
  PERMISSION_MANAGE_POS,
  PERMISSION_VIEW_FAMILY,
  PERMISSION_VIEW_FARES,
  PERMISSION_VIEW_FLIGHT,
  PERMISSION_VIEW_HOTEL,
  PERMISSION_VIEW_MARKET,
  PERMISSION_VIEW_POS
} from '../user-permissions.service';
import { LookupOptions } from '../../core/util/lookup-options';
import {
  ClearLookupData, ResetPartitionDestination,
  SetNavigationParams,
  SetPartitionDestination
} from '../../store/navigation/navigation-actions';
import { UpdateSelectedPartition } from '../../store/core/actions';
import { FeatureFlags } from '../../core/util/resources';
import { selectConfiguration } from '../../store/core/selector';
import { ConfigurationOutput } from '@seco/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  selectedMenuTitle = new BehaviorSubject('Dashboard');
  isPosCollapsed = true;
  isMarketsCollapsed = true;
  isHotelsCollapsed = true;
  isFlightsCollapsed = true;
  isFamiliesCollapsed = true;
  isFaresCollapsed = true;
  navigationDisabled = false;
  familiesManageEnabled = false;
  familiesViewEnabled = false;
  flightsManageEnabled = false;
  flightsViewEnabled = false;
  hotelsManageEnabled = false;
  hotelsViewEnabled = false;
  marketsManageEnabled = false;
  marketsViewEnabled = false;
  posManageEnabled = false;
  posViewEnabled = false;
  faresManageEnabled = false;
  faresViewEnabled = false;

  constructor(
    private readonly userDetailsService: UserDetailsService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store
  ) {
    const permissions = this.userDetailsService.loggedInUser?.permissions;
    this.familiesManageEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_MANAGE_FAMILY).length > 0);
    this.familiesViewEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_VIEW_FAMILY).length > 0);
    this.flightsViewEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_VIEW_FLIGHT).length > 0);
    this.hotelsManageEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_MANAGE_HOTEL).length > 0);
    this.hotelsViewEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_VIEW_HOTEL).length > 0);
    this.marketsManageEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_MANAGE_MARKET).length > 0);
    this.marketsViewEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_VIEW_MARKET).length > 0);
    this.posManageEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_MANAGE_POS).length > 0);
    this.posViewEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_VIEW_POS).length > 0);
    this.faresManageEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_MANAGE_FARES).length > 0);
    this.faresViewEnabled = Boolean(permissions && permissions.filter(p => p === PERMISSION_VIEW_FARES).length > 0);
    this.store.pipe(select(selectConfiguration)).subscribe(configuration => {
      // Configurations are loaded asynchronously after this service is initialized, so if we depend on them we have to wait to be loaded.
      this.afterConfigurationLoaded(configuration);
    });
  }

  setSelectedMenuTitle(title: string) {
    this.selectedMenuTitle.next(title);
  }

  togglePos() {
    this.isPosCollapsed = !this.isPosCollapsed;
  }

  activatePos() {
    this.isPosCollapsed = false;
  }

  toggleMarkets() {
    this.isMarketsCollapsed = !this.isMarketsCollapsed;
  }

  activateMarkets() {
    this.isMarketsCollapsed = false;
  }

  toggleHotels() {
    this.isHotelsCollapsed = !this.isHotelsCollapsed;
  }

  activateHotels() {
    this.isHotelsCollapsed = false;
  }

  toggleFlights() {
    this.isFlightsCollapsed = !this.isFlightsCollapsed;
  }
  activateFlights() {
    this.isFlightsCollapsed = false;
  }

  toggleFamilies() {
    this.isFamiliesCollapsed = !this.isFamiliesCollapsed;
  }
  activateFamilies() {
    this.isFamiliesCollapsed = false;
  }

  toggleFares() {
    this.isFaresCollapsed = !this.isFaresCollapsed;
  }

  activateFares() {
    this.isFaresCollapsed = false;
  }

  enableNavigation() {
    this.navigationDisabled = false;
  }

  disableNavigation() {
    this.navigationDisabled = true;
  }

  isNavigationDisabled(): boolean {
    return this.navigationDisabled;
  }

  navigate(lookupOptions?: LookupOptions): void {
    this.store.dispatch(new SetNavigationParams(lookupOptions));

    this.router.navigate([lookupOptions?.destinationComponent]);
  }

  clearLookupData(): void {
    this.store.dispatch(new ClearLookupData());
  }

  selectPartition(partition: string): void {
    this.store.dispatch(new SetPartitionDestination(partition));
    if (this.router.url === '/dashboard') {
      this.onPartitionUpdateConfirmation(partition);
    } else {
      this.router.navigate(['dashboard']).then((result) => {
        if (result) {
          this.onPartitionUpdateConfirmation(partition);
        } else {
          this.store.dispatch( new ResetPartitionDestination());
        }
      });
    }
  }

  afterConfigurationLoaded(configuration: ConfigurationOutput | undefined): void {
    const permissions = this.userDetailsService.loggedInUser?.permissions ?? [];
    const config = configuration?.moduleParameters.upp;
    const isNDCCriteriaActivated = config?.parameters[FeatureFlags.flightsNDCCriteria];
    this.flightsManageEnabled = permissions.filter((p) =>
      isNDCCriteriaActivated
        ? (p === PERMISSION_MANAGE_EDIFACT_RULE || p === PERMISSION_MANAGE_NDC_RULE)
        : (p === PERMISSION_MANAGE_FLIGHT)
    ).length > 0;
  }

  private onPartitionUpdateConfirmation(partition: string): void {
    this.store.dispatch(new UpdateSelectedPartition(partition));
    this.collapseAllMenus();
  }

  private collapseAllMenus(): void {
    this.isPosCollapsed = true;
    this.isFamiliesCollapsed = true;
    this.isFlightsCollapsed = true;
    this.isHotelsCollapsed = true;
    this.isMarketsCollapsed = true;
  }

}
