export interface Journey {
  rule: JourneyRule;
  applicability: JourneyApplicability;
  content: JourneyContent;
}

export interface JourneyRule {
  id?: string;
  version?: string | number;
  organization?: string;
  partitionId?: string;
  name: string;
  description?: string;
  active: boolean;
  weight?: number;
}

export interface JourneyApplicability {
  pointOfSales?: Location[];
  marketPairs?: MarketPair[];
  contentTypes?: ContentType[];
  products?: Product[];
}

export interface JourneyContent {
  scope: JourneyScope;
  subRules: JourneySubRule[];
}

export interface Location {
  name: string;
  id?: string;
}

export interface MarketPair {
  firstMarket: Location;
  secondMarket: Location;
}

export enum JourneyScope {
  Carrier = 'Carrier',
  Route = 'Route',
  Journey = 'FlightsInformation',
  Custom = 'Custom',
  Fare = 'Fare'
}

export enum JourneyContentAction {
  ForceInclude = 'force_include',
  Exclude = 'exclude'
}

export enum ContentType {
  EDIFACT = 'Edifact',
  NDC = 'NDC'
}

export enum Product {
  // Availability
  AVL = 'AVL',
  // Master Pricer Expert
  MPE = 'MPE',
  // Master Pricer Travelboard
  MP = 'MP',
  // Master Pricer X
  MPX = 'MPX'
}

export enum ConnectionType {
  Online = 'Online',
  Interline = 'Interline'
}

export interface JourneySubRule {
  applicability: JourneySubApplicability;
  content: JourneySubContent;
}

export interface JourneySubApplicability {
  carrier?: JourneyCarrier;
  route?: JourneyRoute;
  flightsInformation?: JourneyFlightsInformation;
  fare?: JourneyFare;
}

export interface JourneySubContent {
  action?: JourneyContentAction;
  value?: number;
}

export interface JourneyCarrier {
  carrierInformations?: CarrierData[];
  codeshareInformations?: CodeshareInformation[];
  flightRanges?: FlightRange[];
  connectionType?: ConnectionType;
  segmentPositions?: number[];
}

export interface Market {
  market: Location;
  marketPosition?: number;
}

export interface JourneyRoute {
  routeMarkets: Market[];
}

export interface JourneyFlightsInformation {
  flightCategories: JourneyFlightCategory[];
}

export interface JourneyFare {
  fareTypeNames?: string[];
  fareTypes: FareType[];
  subFareTypes: FareSubType[];
}

export enum JourneyFlightCategory {
  NonStop = 'NonStop',
  Direct = 'Direct',
  Cog = 'COG',
  TwoSegmentConnection = 'Connection2',
  ThreeSegmentConnection = 'Connection3',
  FourSegmentConnection = 'Connection4',
  FiveSegmentConnection = 'Connection5'
}

export enum FareType {
  // Public
  RP = 'RP',
  // Private
  RU = 'RU',
  // Corporate
  RW = 'RW'
}

// Under Fare type RU
export enum FareSubType {
  // Tour operator
  TO = 'TO',
  // Consolidator
  CS = 'CS',
  // Visit friend
  VF = 'VF'
}

export interface CodeshareInformation {
  primeData?: CarrierData;
  operatingCarrierData?: CarrierData;
  marketingCarrierData?: CarrierData;
}

export interface CarrierData {
  carrierCode?: string;
  alliance?: string;
}

export interface FlightRange {
  carrierCode: string;
  startNumber: number;
  endNumber?: number;
  operationalSuffix?: string;
}
