import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PosMarketSearchParameter } from '../../../service/model/pos';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PosElement } from '../../../service/model/pos/pos-element';
import { select, Store } from '@ngrx/store';
import { SetPosSearchValueAction } from '../../../store/pos/pos-actions';
import { selectPosSearchValue } from '../../../store/pos/pos-selector';
import { UserDetailsService } from '../../../service/user-details.service';
import { PosMarketServiceElements } from '../../../service/model/pos/pos-market-service-elements';
import { UppValidatorService } from '../../../service/upp-validator.service';
import { SearchCriteriaComponent } from '../../../base/search-criteria/search-criteria.component';
import { UppComponentNames } from '../../../service/model';
import { selectNavigation } from '../../../store/navigation/navigation-selector';
import { LookupOptions } from '../../../core/util/lookup-options';

@Component({
  selector: 'ama-ng-upp-pos-search-criteria',
  templateUrl: './pos-search-criteria.component.html',
  styleUrls: ['./pos-search-criteria.component.scss']
})
export class PosSearchCriteriaComponent extends SearchCriteriaComponent implements OnInit {

  @Input() sendingStatus: boolean;

  @Output() searchPos = new EventEmitter<PosMarketSearchParameter>();

  readonly COMPONENT_NAME = UppComponentNames.POS;

  posSearchCriteriaForm: UntypedFormGroup;
  readonly: false;

  numberOfItems = 2;

  posElement: PosElement = { location: {}, level: {} };
  searchParameters: PosMarketSearchParameter;

  regionPasteRegExp = /[,|;]/; // separator regex for ',' and ';'
  lookupOptions: LookupOptions;
  lookup = false;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly store: Store<any>,
    private readonly userDetailsService: UserDetailsService,
    private readonly validatorService: UppValidatorService
  ) {
    super();
    this.buildRegionsDescriptionOnly();
  }

  ngOnInit(): void {
    this.createAndLoadSearchForm();
    this.store.select(selectNavigation).subscribe(lookupParams =>
      this.lookupOptions = lookupParams);
    this.lookup = this.lookupOptions?.lookup;
  }

  createAndLoadSearchForm() {
    this.posSearchCriteriaForm = this.formBuilder.group({
      name: this.formBuilder.control(
        '',
        this.validatorService.getValidatorsForControl(UppComponentNames.POS, PosMarketServiceElements.NAME, false)
      ),
      include: this.formBuilder.group({
        world: this.formBuilder.control(false)
      })
    });
    setTimeout(() => {
      this.store.pipe(select(selectPosSearchValue)).subscribe(pos => {
        if (!this.lookup && pos) {
          this.posSearchCriteriaForm.patchValue(pos);
        }
      });
    }, 0);
  }

  clearClicked() {
    this.posSearchCriteriaForm.setValue({
      name: '',
      include: {
        world: false,
        regions: [],
        countries: [],
        cities: [],
        airports: [],
        officeIds: [],
        corporateCodesAndQualifiers: []
      }
    });
  }

  searchClicked() {
    const regionCodes = this.getRegionCodesForDescriptions(
      this.posSearchCriteriaForm.get([PosMarketServiceElements.INCLUDE, PosMarketServiceElements.REGIONS]).value
    );
    this.posElement = {
      location: {
        world: this.posSearchCriteriaForm.get([PosMarketServiceElements.INCLUDE, PosMarketServiceElements.WORLD]).value,
        regions: regionCodes,
        countries: this.posSearchCriteriaForm.get([
          PosMarketServiceElements.INCLUDE,
          PosMarketServiceElements.COUNTRIES
        ]).value,
        cities: this.posSearchCriteriaForm.get([PosMarketServiceElements.INCLUDE, PosMarketServiceElements.CITIES])
          .value,
        airports: this.posSearchCriteriaForm.get([PosMarketServiceElements.INCLUDE, PosMarketServiceElements.AIRPORTS])
          .value
      },
      level: {
        officeIds: this.posSearchCriteriaForm.get([
          PosMarketServiceElements.INCLUDE,
          PosMarketServiceElements.OFFICE_IDS
        ]).value,
        corporateCodesAndQualifiers: this.posSearchCriteriaForm.get([
          PosMarketServiceElements.INCLUDE,
          PosMarketServiceElements.CORPORATE_CODES
        ]).value
      }
    };
    this.initializeSearchParameters();
  }

  initializeSearchParameters() {
    if(this.userDetailsService.loggedInUser.partitions) {
      this.searchParameters = {
        partitionId: this.userDetailsService.loggedInUser.selectedPartition,
        element: this.posElement
      };
    } else {
      this.searchParameters = {
        organization: this.userDetailsService.getLssOrganization(),
        element: this.posElement
      };
    }
    const name = this.posSearchCriteriaForm.get(PosMarketServiceElements.NAME).value as string;
    if (name && name.length > 0) {
      this.searchParameters.name = name;
    }

    this.emitSearchParameters();
  }

  // Search-parameters(form value) will be emitted to the PosSearchComponent, in order to create a search request.
  emitSearchParameters() {
    this.searchPos.emit(this.searchParameters);
  }

  storeUnsavedPosSearch() {
    this.store.dispatch(new SetPosSearchValueAction({ value: this.posSearchCriteriaForm.value }));
  }
}
