import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { LookupOptions } from '../../core/util/lookup-options';
import { NavigationService } from '../../service/core/navigation.service';
import { UppComponent, UppComponentNames, UppViewNames } from '../../service/model';
import { UserDetailsService } from '../../service/user-details.service';
import {
  PERMISSION_MANAGE_EDIFACT_RULE,
  PERMISSION_MANAGE_NDC_RULE,
  PERMISSION_VIEW_MARKET,
  PERMISSION_VIEW_POS
} from '../../service/user-permissions.service';
import { selectAllAvailableMarketsRecords } from '../../store/markets/markets-selector';
import { selectAllAvailablePosRecords } from '../../store/pos/pos-selector';
import { JourneyUi, JourneyUiSubRule, initialJourneyUi } from '../model';
import { Subscription } from 'rxjs';
import { marketPairsUniqueValidator } from '../../components/form-validators/market-pairs-unique.validator';
import { ButtonGroupItem, hasActiveButtonItem } from '../../model/button-group-item';
import { excludeSubruleShouldBePresent } from './validators/subrule-validators';
import { ConfigurationService } from '../../service/configuration/configuration.service';
import { FeatureFlags } from '../../core/util/resources';
import { ContentType, Product } from '../model/journey';
import { ValueLabelItem } from '../../model/value-label-item';
import {
  MAX_LENGTH_STRING_VALIDATION_MESSAGES,
  NAME_WITH_SPACES_VALIDATION_MESSAGES,
  SECTION_VALIDATION_MESSAGES
} from '../../service/model/common/validation-messages';

const NAME_REGEX = '^[A-Z0-9 ]{0,30}$';

const MARKET_LOOKUP_OPTIONS: LookupOptions = {
  destinationComponent: UppComponentNames.MARKETS,
  sourceComponent: UppComponentNames.FLIGHTS,
  sourceView: UppViewNames.CREATE
};

@Component({
  selector: 'ama-ng-upp-flights-details',
  templateUrl: './flights-details.component.html',
  styleUrls: ['./flights-details.component.scss']
})
export class FlightsDetailsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() flightDetailsForm: UntypedFormGroup;
  @Input() flightDetails: JourneyUi = initialJourneyUi;
  @Input() readonly = true;
  @Input() parent: UppComponent;

  readonly MAX_NUMBER_OF_MARKET_PAIRS = 10;

  NAME_WITH_SPACES_VALIDATION_MESSAGES = NAME_WITH_SPACES_VALIDATION_MESSAGES;
  MAX_LENGTH_STRING_VALIDATION_MESSAGES = MAX_LENGTH_STRING_VALIDATION_MESSAGES;
  SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;

  contentValidationMessages = {
    excludeSubruleRequired: () =>
      $localize`:@@upp.validation.flights.rule.actionOrValue.addExcludeRule:Add a rule content with Exclude action`,
    sortValueOutOfRange: (params: any) =>
      $localize`:@@upp.validation.flights.rule.actionOrValue.sortValueOutOfRange:Minimum/maximum value exceeded: ${params.min} to +${params.max}`,
    required: () => $localize`:@@upp.validation.flights.content.section.required:Section is required`,
    sortValueRequired: () => $localize`:@@upp.validation.required:Field is required`,
    sortValueNotInteger: () =>
      $localize`:@@upp.validation.flights.rule.actionOrValue.sortValueNotInteger:Sort value must be an integer`
  };
  marketPairsValidationMessages = {
    duplicateMarketPair: () =>
      $localize`:@@upp.validation.flights.applicability.marketPairs.duplicate:Market pair already exists`
  };

  posLookupOptions: LookupOptions;

  availablePosNames: string[] = [];
  availableMarketsNames: string[] = [];

  subRuleDefinitions: SubRuleDefinition[] = [];

  showFlightsNDCCriteria = false;
  showFlightsYY = false;
  airContentTypeItems: ValueLabelItem<string>[];
  hasNDCContentType = false;
  hasEdifactContentType = true;
  showFlightsYYWarningMessage = false;

  productItems = [{
    value: Product.AVL,
    label: 'Availability'
  }, {
    value: Product.MPE,
    label: 'Master Pricer Expert'
  }, {
    value: Product.MP,
    label: 'Master Pricer Travelboard'
  }, {
    value: Product.MPX,
    label: 'Master Pricer X'
  }];

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly userDetailsService: UserDetailsService,
    private readonly store: Store,
    private readonly navigationService: NavigationService,
    private readonly configurationService: ConfigurationService
  ) {
    this.showFlightsNDCCriteria = this.configurationService.getParameter<boolean>(FeatureFlags.flightsNDCCriteria);
    this.showFlightsYY = this.configurationService.getParameter<boolean>(FeatureFlags.flightsYY);
  }

  get name(): AbstractControl {
    return this.flightDetailsForm.get('rule.name');
  }

  get description(): AbstractControl {
    return this.flightDetailsForm.get('rule.description');
  }

  get active(): AbstractControl {
    return this.flightDetailsForm.get('rule.active');
  }

  get marketPairs(): FormArray {
    return this.flightDetailsForm.get('applicability.marketPairs') as FormArray;
  }

  get marketPairsControls(): FormGroup[] {
    return this.marketPairs.controls as FormGroup[];
  }

  get applicability(): FormGroup {
    return this.flightDetailsForm.get('applicability') as FormGroup;
  }

  get contentTypes(): AbstractControl | null {
    return this.showFlightsNDCCriteria ? this.applicability.get('contentTypes') : null;
  }

  get subRules(): FormArray {
    return this.flightDetailsForm.get('content.subRules') as FormArray;
  }

  get products(): AbstractControl {
    return this.flightDetailsForm.get('applicability.products');
  }

  get areBothContentTypesSelected(): boolean {
    return this.showFlightsNDCCriteria && this.hasEdifactContentType && this.hasNDCContentType;
  }

  // If both content types are selected then warning message should not be displayed.
  get showWarningMessage(): boolean {
    if (!this.showFlightsYYWarningMessage || this.readonly) {
      return false;
    }
    return !this.areBothContentTypesSelected;
  }

  ngOnInit(): void {
    if (this.showFlightsNDCCriteria) {
      this.airContentTypeItems = [{
        value: ContentType.EDIFACT,
        label: 'EDIFACT'
      }, {
        value: ContentType.NDC,
        label: 'NDC'
      }];
    }

    if (this.canLookupPos()) {
      this.posLookupOptions = {
        destinationComponent: UppComponentNames.POS,
        sourceComponent: UppComponentNames.FLIGHTS,
        sourceView: this.flightDetails.rule.id ? UppViewNames.MODIFY : UppViewNames.CREATE
      };
    }

    this.subscriptions.push(this.store.pipe(select(selectAllAvailablePosRecords)).subscribe(result => {
      if (result != null) {
        this.availablePosNames = result.map(posMarketRecord => posMarketRecord.posMarketDetail.name);
      }
    }));

    this.subscriptions.push(this.store.pipe(select(selectAllAvailableMarketsRecords)).subscribe(result => {
      if (result != null) {
        this.availableMarketsNames = result.map(posMarketRecord => posMarketRecord.posMarketDetail.name);
      }
    }));

    this.navigationService.enableNavigation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.flightDetails) {
      this.flightDetails = JSON.parse(JSON.stringify(changes.flightDetails.currentValue));
      this.initializeForm();
    }
  }

  clearScreen(): void {
    // Keep the name in case of Update (Modify, Display)
    const name = this.parent === UppComponent.FLIGHTS_CREATE ? '' : this.flightDetails.rule.name;
    const id = this.flightDetails.rule.id;
    this.flightDetails = JSON.parse(JSON.stringify({
      ...initialJourneyUi,
      rule: {
        ...initialJourneyUi.rule,
        name,
        id
      },
      applicability: {
        ...initialJourneyUi.applicability,
        contentTypes: this.getDefaultContentTypes(true)
      }
    }));

    if (this.showFlightsNDCCriteria) {
      this.initializeContentTypes();
      this.flightDetails.applicability.contentTypes = this.contentTypes.value;
    }

    this.initializeMarketPairs();
    this.initializeSubRules();

    this.flightDetailsForm.reset(this.flightDetails);
  }

  getMarketLookupOptions(): LookupOptions {
    if (this.canLookupMarket()) {
      return {...MARKET_LOOKUP_OPTIONS, sourceView: this.flightDetails.rule.id ? UppViewNames.MODIFY : UppViewNames.CREATE};
    }
    return null;
  }

  addBidirectionalMarketPair(atPosition: number, originalMarketPair: any): void {
    this.addMarketPair(atPosition, {
      firstMarketName: originalMarketPair.secondMarketName,
      secondMarketName: originalMarketPair.firstMarketName
    });
  }

  addMarketPair(atPosition?: number, marketPair?: any): void {
    const marketPairFormGroup = this.formBuilder.group({
      firstMarketName: [{value: marketPair?.firstMarketName, disabled: this.readonly}, [Validators.required]],
      secondMarketName: [{value: marketPair?.secondMarketName, disabled: this.readonly}, [Validators.required]]
    }, {validators: marketPairsUniqueValidator()});

    // Touch to force display duplicated validation error if any
    marketPairFormGroup.markAsTouched();

    this.subscriptions.push(marketPairFormGroup.valueChanges.subscribe(() => {
      this.validateMarketPairs(marketPairFormGroup);
    }));
    this.marketPairs.insert(atPosition ?? this.marketPairs.length, marketPairFormGroup);

    this.validateMarketPairs();
  }

  removeMarketPair(index: number): void {
    this.marketPairs.removeAt(index);
    this.validateMarketPairs();
  }

  addSubRule(atPosition?: number, subRule?: JourneyUiSubRule): void {
    const subRuleFormGroup = this.formBuilder.group({
      hasRuleContent: this.formBuilder.control(false, Validators.requiredTrue),
      applicability: this.formBuilder.group({}),
      content: this.formBuilder.control({ value: subRule?.content, disabled: this.readonly })
    });

    this.subRules.insert(atPosition ?? this.subRules.length, subRuleFormGroup);

    const ruleContentButtonGroupItems = this.generateRuleContentButtonGroupItems();
    const subRuleDefinition: SubRuleDefinition = {
      ruleContentButtonGroupItems,
      ruleContentButtonGroupMap: ruleContentButtonGroupItems.reduce((acc: any, item: any) => {
        acc[item.name] = item;
        return acc;
      }, {}),
      formGroup: subRuleFormGroup
    };

    if (subRule) {
      // Clone sub rule to make it modifiable.
      subRuleDefinition.subRule = JSON.parse(JSON.stringify(subRule));
    }

    this.subRuleDefinitions.splice(atPosition ?? this.subRules.length, 0, subRuleDefinition);
  }

  removeSubRule(index: number): void {
    this.subRules.removeAt(index);
    this.subRuleDefinitions.splice(index, 1);
  }

  onRuleContentToggle(buttonGroupItem: ButtonGroupItem, subRuleDefinition: SubRuleDefinition): void {
    const applicabilityFormGroup = subRuleDefinition.formGroup.get('applicability') as FormGroup;
    if (buttonGroupItem.active) {
      applicabilityFormGroup.addControl(buttonGroupItem.name, this.formBuilder.group({}));
    } else {
      applicabilityFormGroup.removeControl(buttonGroupItem.name);
      // Once collapsed section (scope) data should be cleared. Otherwise if expanded again it will be prefilled (in case of modify).
      delete subRuleDefinition.subRule?.applicability?.[buttonGroupItem.name as keyof typeof subRuleDefinition.subRule.applicability];
    }

    const hasRuleContentControl = subRuleDefinition.formGroup.get('hasRuleContent');
    hasRuleContentControl.setValue(hasActiveButtonItem(subRuleDefinition.ruleContentButtonGroupItems));
    hasRuleContentControl.markAsTouched();
    this.showFlightsYYWarningMessage = this.showFlightsYY && hasRuleContentControl.value;
  }

  onAirContentTypeChange(): void {
    // Clear the data in case we are in modify mode.
    if (this.flightDetails?.content?.subRules) {
      this.flightDetails.content.subRules = [];
    }
    this.showFlightsYYWarningMessage = false;
    this.initializeSubRules();
  }

  ngOnDestroy(): void {
    this.flightDetailsForm.reset({});

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.subscriptions = [];
  }

  private validateMarketPairs(marketPairToSkip?: any): void {
    this.marketPairs.controls.forEach(control => {
      if (!marketPairToSkip || control !== marketPairToSkip) {
        control.updateValueAndValidity({emitEvent: false});
      }
    });
  }

  private canLookupPos(): boolean {
    return this.canLookup(PERMISSION_VIEW_POS);
  }

  private canLookupMarket(): boolean {
    return this.canLookup(PERMISSION_VIEW_MARKET);
  }

  private canLookup(permisionName: string): boolean {
    if (this.readonly) {
      return false;
    }
    const permissions = this.userDetailsService.loggedInUser.permissions;
    return permissions.filter(p => p === permisionName).length > 0;
  }

  private initializeForm(): void {
    if (this.flightDetailsForm) {
      this.flightDetailsForm.setControl(
        'rule',
        this.formBuilder.group({
          id: [this.flightDetails?.rule?.id],
          version: [this.flightDetails?.rule?.version],
          organization: [this.userDetailsService.getLssOrganization()],
          name: [
            { value: this.flightDetails?.rule?.name, disabled: this.readonly || this.parent !== UppComponent.FLIGHTS_CREATE },
            [Validators.required, Validators.maxLength(30), Validators.pattern(NAME_REGEX)]
          ],
          description: [
            { value: this.flightDetails?.rule?.description, disabled: this.readonly },
            [Validators.maxLength(128)]
          ],
          active: [{ value: this.flightDetails?.rule?.active, disabled: this.readonly }]
        })
      );

      this.flightDetailsForm.setControl(
        'applicability',
        this.formBuilder.group({
          pointOfSaleName: [
            { value: this.flightDetails?.applicability?.pointOfSaleName, disabled: this.readonly },
            [Validators.required]
          ],
          marketPairs: this.formBuilder.array([], [Validators.required])
        })
      );

      if (this.showFlightsNDCCriteria) {
        this.initializeContentTypes();
      }

      if (this.hasEdifactContentType && !this.hasNDCContentType) {
        this.applicability.setControl('products', this.formBuilder.control({
          value: this.flightDetails?.applicability?.products,
          disabled: this.readonly
        }, [Validators.required]));
      }

      this.flightDetailsForm.setControl('content', this.formBuilder.group({
          subRules: this.formBuilder.array([], excludeSubruleShouldBePresent)
        })
      );

      this.showFlightsYYWarningMessage = false;
      this.initializeMarketPairs();
      this.initializeSubRules();
    }
  }

  private initializeContentTypes(): void {
    let initialContentTypes: ContentType[] = [];
    if (this.flightDetails.rule.id) {
      // In case that entity was created before adding content types property.
      initialContentTypes = this.flightDetails?.applicability?.contentTypes ?? [ContentType.EDIFACT];
    } else {
      initialContentTypes = this.getDefaultContentTypes(false);
    }
    // Content type is disabled if user doesn't have both permissions.
    const disableContentType = !this.hasManageEdifactPermission() || !this.hasManageNDCPermission();

    const contentTypesControl = this.formBuilder.control({
      value: initialContentTypes,
      disabled: this.readonly || disableContentType
    }, [Validators.required]);

    this.hasNDCContentType = initialContentTypes.includes(ContentType.NDC);
    this.hasEdifactContentType = initialContentTypes.includes(ContentType.EDIFACT);

    this.subscriptions.push(contentTypesControl.valueChanges.subscribe((val) => {
      // Keep in local variable to avoid calling a method each time.
      this.hasNDCContentType = val?.includes(ContentType.NDC);
      this.hasEdifactContentType = val?.includes(ContentType.EDIFACT);

      if (this.hasNDCContentType || !this.hasEdifactContentType) {
        this.applicability.removeControl('products');
      } else {
        this.applicability.setControl('products', this.formBuilder.control({ value: [] }, [Validators.required]));
      }
    }));

    this.applicability.setControl('contentTypes', contentTypesControl);
  }

  private hasManageEdifactPermission(): boolean {
    return this.userDetailsService.loggedInUser.permissions.includes(PERMISSION_MANAGE_EDIFACT_RULE);
  }

  private hasManageNDCPermission(): boolean {
    return this.userDetailsService.loggedInUser.permissions.includes(PERMISSION_MANAGE_NDC_RULE);
  }

  private initializeMarketPairs() {
    this.marketPairs.clear();

    if (this.flightDetails.applicability?.marketPairs) {
      const marketPairs = this.flightDetails.applicability.marketPairs;

      for (const marketPair of marketPairs) {
        this.addMarketPair(null, marketPair);
      }
    } else {
      this.addMarketPair();
    }
  }

  private initializeSubRules() {
    this.subRuleDefinitions.splice(0, this.subRuleDefinitions.length);
    this.subRules.clear();
    this.initializeSections();
  }

  private generateRuleContentButtonGroupItems(): ButtonGroupItem[] {
    if (this.showFlightsNDCCriteria) {
      const contentTypesValue = this.contentTypes.value;
      if (contentTypesValue?.length === 2) {
        return this.generateRuleContentButtonGroupItemsForBoth();
      } else if (contentTypesValue?.[0] === ContentType.NDC) {
        return this.generateRuleContentButtonGroupItemsForNDC();
      }
    }
    // Default
    return this.generateRuleContentButtonGroupItemsForEDIFACT();
  }

  private generateRuleContentButtonGroupItemsForEDIFACT(): ButtonGroupItem[] {
    return [{
      name: 'carrier',
      active: false,
      disabled: this.readonly,
      title: $localize`:@@upp.global.criteria.carrier.label:Carrier`
    }, {
      name: 'route',
      active: false,
      disabled: this.readonly,
      title: $localize`:@@upp.global.criteria.route.label:Route`
    }, {
      name: 'journey',
      active: false,
      disabled: this.readonly,
      title: $localize`:@@upp.global.criteria.journey.label:Journey`
    }];
  }

  private generateRuleContentButtonGroupItemsForNDC(): ButtonGroupItem[] {
    return [{
      name: 'carrier',
      active: false,
      disabled: this.readonly,
      title: $localize`:@@upp.global.criteria.carrier.label:Carrier`
    }, {
      name: 'fare',
      active: false,
      disabled: this.readonly,
      title: $localize`:@@upp.global.criteria.fare.label:Fare`
    }];
  }

  private generateRuleContentButtonGroupItemsForBoth(): ButtonGroupItem[] {
    return [{
      name: 'carrier',
      active: false,
      disabled: this.readonly,
      title: $localize`:@@upp.global.criteria.carrier.label:Carrier`
    }];
  }

  private initializeSections(): void {
    if (this.flightDetails?.content?.subRules?.length > 0) {
      this.flightDetails.content.subRules.forEach((subRule, index) => {
        this.addSubRule(this.subRules.length, subRule);
        const subRuleDefinition = this.subRuleDefinitions[index];
        if (subRule.applicability.carrier) {
          subRuleDefinition.ruleContentButtonGroupMap.carrier.active = true;
          this.onRuleContentToggle(subRuleDefinition.ruleContentButtonGroupMap.carrier, subRuleDefinition);
        }

        if (subRule.applicability.route) {
          subRuleDefinition.ruleContentButtonGroupMap.route.active = true;
          this.onRuleContentToggle(subRuleDefinition.ruleContentButtonGroupMap.route, subRuleDefinition);
        }

        if (subRule.applicability.journey) {
          subRuleDefinition.ruleContentButtonGroupMap.journey.active = true;
          this.onRuleContentToggle(subRuleDefinition.ruleContentButtonGroupMap.journey, subRuleDefinition);
        }

        if (subRule.applicability.fare) {
          subRuleDefinition.ruleContentButtonGroupMap.fare.active = true;
          this.onRuleContentToggle(subRuleDefinition.ruleContentButtonGroupMap.fare, subRuleDefinition);
        }
      });
    } else if (!this.showFlightsNDCCriteria || this.contentTypes.value?.length > 0) {
      // By default (if NDC is activated) shouldn't have a subrule until at least one content type is selected.
      this.addSubRule();
    }
  }

  private getDefaultContentTypes(clear: boolean): ContentType[] {
    if (this.hasManageEdifactPermission() && this.hasManageNDCPermission()) {
      return clear ? [] : (this.flightDetails?.applicability?.contentTypes ?? []);
    } else if (this.hasManageEdifactPermission()) {
      return [ContentType.EDIFACT];
    } else if (this.hasManageNDCPermission()) {
      return [ContentType.NDC];
    }
    return [];
  }
}

interface SubRuleDefinition {
  ruleContentButtonGroupItems: ButtonGroupItem[];
  ruleContentButtonGroupMap: { [key: string]: ButtonGroupItem };

  formGroup: FormGroup;
  subRule?: JourneyUiSubRule;
}
