
<label class="d-block form-label">
  <ng-container i18n="@@upp.flights.details.routeContentSelectLabel">Route content</ng-container>
</label>
<div class="route-content-section mb-5" *ngFor="let contentForm of marketsFormArray.controls; let i = index">
  <div [formGroup]="contentForm" class="criteria-container">
    <div class="d-flex w-100">
      <div class="market-container">
        <label for="market" class="form-label">
          <ng-container i18n="@@upp.flights.details.marketNameLabel">Market</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="market" formControlName="name"
          [items]="availableMarkets" [lookupOptions]="readonly ? null : marketLookupOptions"
          notFoundText="No markets found" i18n-notFoundText="@@upp.global.criteria.markets.notFoundText">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="contentForm.get('name')"></ama-ng-upp-validation-error-message>
      </div>
      <div class="market-position-container d-inline-block">
        <label i18n="@@upp.flights.details.marketPosition.label" for="position" class="form-label">Market position</label>
        <ama-ng-upp-lookup-select
          id="position"
          formControlName="position"
          [items]="marketPositionOptions"
          ngbTooltip="Select a market position between -6 and 6 (zero is not allowed)."
          i18n-ngbTooltip="@@upp.flights.details.marketPosition.tooltip">
        </ama-ng-upp-lookup-select>
      </div>
      <div *ngIf="!readonly" class="icon-container">
        <button
          class="btn btn-outline-primary df-btn-icononly icon-plus mt-7 mb-5 ms-2 flex-grow-auto"
          type="button"
          (click)="addButtonClicked()">
        </button>
        <ng-container *ngIf="marketsFormArray.length > 1">
          <button
            class="btn btn-outline-primary df-btn-icononly icon-trash-alt df-icon-solid mt-7 mb-5 ms-2 flex-grow-auto"
            type="button"
            (click)="removeButtonClicked(i)">
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
