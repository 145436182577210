import { Injectable } from '@angular/core';
import { AnalyticsArguments, AnalyticsService } from '@seco/core';

/**
 * An analytics service implementation that doesn't do anything
 */
@Injectable({
  providedIn: 'root'
})
export class DummyAnalyticsService implements AnalyticsService {

  track(_args: AnalyticsArguments): void {
  }

}
